import { useQuery } from "react-query";
import { useCallback } from "react";
import axios from "axios";

export function useLoadBalancerLive() {
  const queryItem = useCallback(async () => {
    const api = process.env.REACT_APP_API_URI;
    const { data } = await axios.get(api + "/balancer/live");
    return data?.live;
  }, []);

  return useQuery<boolean, Error>("loadBalancerLive", queryItem, {
    initialData: false,
  });
}
