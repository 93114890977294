import { useQuery } from "react-query";
import { useCallback } from "react";
import axios from "axios";
import { Order } from "../../../common";

export function useLoadOrders({ live }) {
  const refetchInterval = live ? 1000 : 30000;
  const queryItem = useCallback(async () => {
    const api = process.env.REACT_APP_API_URI;
    const { data } = await axios.get(api + "/cex/ftx/treasury/orders");
    return data.orders || [];
  }, []);

  return useQuery<Order[], Error>("loadOrders", queryItem, {
    refetchInterval,
    initialData: [],
  });
}
