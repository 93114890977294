export enum LegTypes {
  Reward = "Reward",
  Principle = "Principle",
  Lend = "Lend",
  Borrow = "Borrow",
  Hedge = "Hedge",
  Lock = "Lock",
  Collateral = "Collateral",
}

export enum Strategies {
  MORPHO = "morpho", // Provide CRV-ETH Curve LP and stake in Convex
  CRV_ETH_LP = "crv_eth_lp", // Provide CRV-ETH Curve LP and stake in Convex
  CVXCRV_CRV_LP = "cvxcrv_crv_lp", // Provide CVXCRV-CRV Curve LP and stake in Convex
  MIM_3CRV_LP = "mim_3crv_lp", // Provide MIM-3CRV Curve LP and stake in Convex
  AAVE_MAINNET = "aave_mainnet", // Lend and Borrow on  AAVE mainnet
  AAVE_FANTOM = "aave_fantom", // Lend and Borrow on  AAVE mainnet
  CVXCRV_STAKE = "cvxcrv_stake", // Stake CVXCRV on Convex
  GMX_ARBITRUM = "gmx_arbitrum", // Buy hedged GLP on Arbitrum
  GMX_ARBITRUM_PRINCIPLE = "gmx_arbitrum_principle", // Principle pnl of GMX
  HEDGES = "hedges", // Perpetual hedges
  TREASURY = "treasury", // Treasury data
  BORROWED_CAPITAL = "borrowed_capital", // Capital borrowed for investing
  LOOKS_STAKING = "looks_staking", // Treasury data
  TESTING = "testing", // Treasury data
  CEL_FUNDING = "celsius_funding", // Treasury data
  ETH_FUNDING = "eth_funding", // Treasury data
  OPERATION_COST = "operation_cost", // Operation Costs
  GEARBOX = "gearbox", // Operation Costs
  GEARBOX_CREDIT = "gearbox-credit", // Gearbox Credit
  CHICKEN_BONDS = "chicken_bonds", // Chicken bonds
  CURVE_3CRV_BLUSD = "curve_3crv_blusd", // Chicken bonds
  TRADING_PORTFOLIO = "trading_portfolio", // Trading Portfolio
  IPOR = "ipor", // IPOR
  STURDY = "sturdy",
  EUSD_3CRV_LP = "eusd_3crv_lp",
  EUSD_FRAX_LP = "eusd_frax_lp",
  MAKER_DSR = "maker_dsr",
  EIGEN_LAYER = "eigen_layer",
  USDC_TRICRYPTO = "usdc_tricrypto",
  ETH_PLUS = "eth_plus",
  UNI_V3 = "uni_v3",
  FRAXLEND = "fraxlend",
  PENDLE = "pendle",
  USDC_PLUS = "usdc_plus",
  HYUSD_EUSD = "hyusd_eusd",
  GMX_ARBITRUM_V2 = "gmx_arbitrum_v2",
}
