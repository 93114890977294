import {
  Button,
  Space,
  Row,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Select,
  InputNumber,
  Collapse,
} from "antd";
import { Strategies, LegTypes } from "@cosmopolis/common";
import { useRegisterPendingPosition } from "./hooks";
import { useEffect } from "react";

const { Option } = Select;
const { Panel } = Collapse;

export function AddPendingPositionModal({
  show,
  onClose,
  asset,
  amount,
  strategyId,
  legType,
}: {
  show: boolean;
  onClose?: any;
  asset?: string;
  amount?: number;
  strategyId?: string;
  legType?: string;
}) {
  const { action: registerPendingPosition } = useRegisterPendingPosition();
  const [form] = Form.useForm();
  useEffect(() => form.resetFields(), [asset, amount, strategyId]);
  return (
    <Drawer
      size="large"
      title="Add Pending Position"
      open={show}
      onClose={onClose}
      extra={
        <Space>
          <Button
            onClick={() => {
              form.resetFields();
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  registerPendingPosition({
                    asset: values.asset,
                    amount: values.amount,
                    strategyId: values.strategyId,
                    legType: values.legType,
                    instructions: {
                      pair: values.pair,
                      pairMaxDiff: values.pairMaxDiff,
                    },
                  });
                  onClose();
                })
                .catch((info) => {
                  console.log("Validate Failed:", info);
                });
            }}
            type="primary"
          >
            Submit
          </Button>
        </Space>
      }
    >
      <Form
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        autoComplete="off"
        size="large"
      >
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              initialValue={asset}
              label="Asset"
              name="asset"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              initialValue={amount}
              label="Amount"
              name="amount"
              rules={[{ required: true }]}
            >
              <InputNumber size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Divider />
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              initialValue={strategyId}
              name={"strategyId"}
              rules={[{ required: true, message: "Strategy ID is required" }]}
            >
              <Select placeholder="Strategy" size="large">
                {Object.keys(Strategies).map((key) => (
                  <Option key={`strategy-${key}`} value={Strategies[key]}>
                    {key}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              initialValue={legType}
              name={"legType"}
              rules={[{ required: true, message: "Leg type is required" }]}
            >
              <Select placeholder="Leg Type">
                {Object.keys(LegTypes).map((key) => (
                  <Option key={`legtype-${key}`} value={LegTypes[key]}>
                    {key}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Collapse defaultActiveKey={["1"]} ghost>
            <Panel header="Advanced" key="1">
              <Row gutter={16}>
                <Col span={16}>
                  <Form.Item label="Pair Asset" name="pair">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Pair Max Diff" name="pairMaxDiff">
                    <InputNumber size="large" />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Row>
      </Form>
    </Drawer>
  );
}
