import React from "react";
import { AppRouter } from "./Router";
import { Boot } from "./Boot";
import { Root } from "./Root";

export const App = () => {
  return (
    <>
      <Boot>
        <Root>
          <AppRouter />
        </Root>
      </Boot>
    </>
  );
};
