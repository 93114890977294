import { Page } from "../common";
import { Layout, Tabs, Row, Col, Typography, Space, Spin, Button } from "antd";
import { formatRelative } from "date-fns";
import { useState, useEffect } from "react";
import { BorrowHistory } from "./BorrowHistory";
import { useLoadBorrowedCapital } from "./hooks/loadBorrowedCapital";
export function BorrowedCapitalPage() {
  const { data, isLoading, isError, error } = useLoadBorrowedCapital();

  return (
    <Page>
      <Layout.Header
        style={{
          backgroundColor: "transparent",
          padding: 0,
          marginTop: 0,
          height: "auto",
        }}
      >
        <Row>
          <Col flex={1}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                lineHeight: 0,
                marginBottom: 24,
                alignItems: "baseline",
              }}
            >
              <div style={{ marginRight: "18px" }}>
                <Typography.Title level={1}>Borrowed Capital</Typography.Title>
              </div>
              <div>
                {isLoading && !isError && <Spin />}
                {error && (
                  <Typography.Text type="danger">
                    {error.message}
                  </Typography.Text>
                )}
              </div>
            </div>
          </Col>
          <Col></Col>
        </Row>
      </Layout.Header>
      <Tabs
        defaultActiveKey="1"
        tabBarStyle={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
      >
        <Tabs.TabPane tab="History" key="1">
          <BorrowHistory transactions={data?.transactions} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Summary" key="2">
          <div></div>
        </Tabs.TabPane>
      </Tabs>
    </Page>
  );
}
