import "./report.css";

import { Card, Col, Divider, Row, Space, Statistic, Typography } from "antd";
import { Liquid, RadialBar, TinyArea } from "@ant-design/plots";
import { toNiceNumber, toNicePerc } from "@cosmopolis/common";
import { useEffect, useState } from "react";

import { ProCard } from "@ant-design/pro-card";
import { format } from "date-fns";
import { useLoadReport } from "./hooks/loadReport";
import { useLocation } from "react-router";

function getLegIndex(leg) {
  const { legType } = leg;
  switch (legType) {
    case "Principle":
      return 0;
    case "Hedge":
      return 1;
    case "Lend":
      return 2;
    case "Borrow":
      return 3;
    case "Reward":
      return 4;
    default:
      return 5;
  }
}
export const StatusReport = ({ report }) => {
  let { search } = useLocation();
  const q = new URLSearchParams(search);
  const timestamp = q.get("timestamp");

  if (!report) {
    return <></>;
  }
  return (
    <div style={{ paddingTop: 0 }}>
      <Summary data={report.summary} report={report} />
      <Row gutter={[8, 8]} style={{ marginTop: "8px" }}>
        <Col xs={{ span: 24 }} xl={{ span: 16 }}>
          <Card title="Hedging Positions">
            <Hedging hedging={report.hedging} />
          </Card>
        </Col>
        <Col xs={{ span: 24 }} xl={{ span: 8 }}>
          <Row gutter={[8, 8]}>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 24 }}>
              <Card title="Wallets">
                <WalletSummary wallets={report.wallets} />
              </Card>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 24 }}>
              <Card title="CEX" style={{ marginTop: "8px" }}>
                <CexSummary cex={report.cex} />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export function SummaryCard({
  title,
  value,
  leftValue,
  rightValue,
}: {
  title: string;
  value: any;
  leftValue?: any;
  rightValue?: any;
}) {
  return (
    <Card style={{ height: "170px" }}>
      <Typography.Text type="secondary">{title}</Typography.Text>
      <Typography.Title
        level={3}
        style={{ margin: 0, marginBottom: "10px", fontWeight: 400 }}
      >
        {value}
      </Typography.Title>
      <DemoTinyArea />
      <Divider style={{ marginTop: 10, marginBottom: 5 }} />
      <Row justify="space-between">
        <Col>
          <Typography.Text type="secondary">{leftValue}</Typography.Text>
        </Col>
        <Col>
          <Typography.Text type="secondary">{rightValue}</Typography.Text>
        </Col>
      </Row>
    </Card>
  );
}

const DemoTinyArea = () => {
  const data = [
    264, 417, 438, 887, 309, 397, 550, 575, 563, 430, 525, 592, 492, 467, 513,
    546, 983, 340, 539, 243, 226, 192,
  ];
  const config = {
    autoFit: true,
    height: 22,
    data,
    smooth: true,
    areaStyle: {
      fill: "#d6e3fd",
    },
  };
  return <TinyArea {...config} />;
};

const Summary = ({ data, report }) => {

  let totalInterest = 0;

  if(report && report.borrowedCapital){
    const interestLegs = Object.values(report.borrowedCapital.legs).filter((leg: any)=>leg.legId.includes("interest"));
    totalInterest = Number(interestLegs.reduce((acc, leg: any) => acc + leg.current.value, 0));
  
  }
 
  return (
    <>
      <Row gutter={[8, 8]} justify="space-between">
        <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 4 }}>
          <SummaryCard
            title="AUM"
            value={`\$${toNiceNumber(data.totalValue, 3)}`}
            leftValue={`\$${toNiceNumber(totalInterest, 3)}`}
            rightValue={`\$${toNiceNumber(data.pnl, 3)}`}
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 4 }}>
          <SummaryCard
            title="Strategies"
            value={`\$${toNiceNumber(data.strategies)}`}
            leftValue={`offset \$${toNiceNumber(data.strategiesOffset)}`}
          />
        </Col>

        <Col xs={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 4 }}>
          <SummaryCard
            title="Lending"
            value={`\$${toNiceNumber(data.lending)}`}
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 4 }}>
          <SummaryCard
            title="Borrows"
            value={`\$${toNiceNumber(data.borrows)}`}
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 4 }}>
          <SummaryCard title="Spot" value={`\$${toNiceNumber(data.spot)}`} />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 4 }}>
          <Card>
            <div style={{ width: "120px", height: "120px", margin: "auto" }}>
              <Liquid
                statistic={{
                  title: {
                    style: {
                      color: "#363636",
                      fontSize: "12px",
                      lineHeight: "14px",
                    },
                    formatter: () => "Utilization",
                  },
                  content: {
                    style: {
                      fontSize: "18px",
                      lineHeight: "22px",
                    },
                  },
                }}
                outline={{
                  border: 2,
                  distance: 2,
                }}
                wave={{
                  length: 28,
                }}
                width={120}
                height={120}
                padding={[0, 0]}
                autoFit={false}
                percent={data.utilization / 100}
              />
            </div>
          </Card>
        </Col>
      </Row>

      <style>
        {`
        .g2-html-annotation{
          color: white !important;
        }
      `}
      </style>
    </>
  );
};

const Hedging = ({ hedging }) => {
  return (
    <table width="100%" style={{ textAlign: "center" }}>
      <thead style={{ backgroundColor: "#DDD" }}>
        <tr>
          <td>Asset</td>
          <td>Size</td>
          <td>Price</td>
          <td>Value</td>
          <td>Hourly Funding</td>
          <td>Daily Funding</td>
          <td>Weekly Funding</td>
        </tr>
      </thead>
      <tbody>
        {hedging.positions.map((positions) => {
          return (
            <tr>
              <td>{positions.asset}</td>
              <td>{toNiceNumber(positions.amount)}</td>
              <td>{toNiceNumber(positions.price)}</td>
              <td>${toNiceNumber(positions.value)}</td>
              <td>
                ${toNiceNumber(positions.hourlyFunding)} (
                {toNicePerc(positions.hourRate)})
              </td>
              <td>
                ${toNiceNumber(positions.dailyFunding)} (
                {toNicePerc(positions.dailyRate)})
              </td>
              <td>
                ${toNiceNumber(positions.weeklyFunding)} (
                {toNicePerc(positions.weeklyRate)})
              </td>
            </tr>
          );
        })}
        <tr
          style={{
            borderTop: "1px solid black",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          <td colSpan={3}></td>
          <td>${toNiceNumber(hedging.summary.totalValue)}</td>
          <td>
            ${toNiceNumber(hedging.summary.totalHourlyFunding || 0)} (
            {toNicePerc(hedging.summary.avgHourlyRate || 0)})
          </td>
          <td>
            ${toNiceNumber(hedging.summary.totalDailyFunding)} (
            {toNicePerc(hedging.summary.avgDailyRate)})
          </td>
          <td>
            ${toNiceNumber(hedging.summary.totalWeeklyFunding)} (
            {toNicePerc(hedging.summary.avgWeeklyRate)})
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const CexSummary = ({ cex }) => {
  return (
    <table style={{ width: "100%" }}>
      <thead style={{ backgroundColor: "#DDD" }}>
        <tr>
          <td>Account</td>
          <td>Platform</td>
          <td>Net</td>
        </tr>
      </thead>
      <tbody>
        {cex.accounts.map((account) => {
          return (
            <tr>
              <td style={{ textAlign: "left" }}>{account.account}</td>
              <td>{account.platform}</td>
              <td>${toNiceNumber(account.value)}</td>
            </tr>
          );
        })}
        <tr style={{ borderTop: "1px solid black" }}>
          <td></td>
          <td></td>
          <td>${toNiceNumber(cex.summary.totalBalance)}</td>
        </tr>
      </tbody>
    </table>
  );
};

const WalletSummary = ({ wallets }) => {
  return (
    <table style={{ width: "100%" }}>
      <thead style={{ backgroundColor: "#DDD" }}>
        <tr>
          <td>Wallet</td>
          <td>Spot Value</td>
          <td>Net Value (approx.)</td>
        </tr>
      </thead>
      <tbody>
        {wallets.wallets.map((wallet, index) => {
          return (
            <tr key={`wallet-${index}`}>
              <td style={{ textAlign: "left" }}>{wallet.name}</td>
              <td>${toNiceNumber(wallet.totalSpot)}</td>
              <td>${toNiceNumber(wallet.net)}</td>
            </tr>
          );
        })}
        <tr style={{ borderTop: "1px solid black" }}>
          <td></td>
          <td>${toNiceNumber(wallets.summary.totalSpot)}</td>
          <td>${toNiceNumber(wallets.summary.net)}</td>
        </tr>
      </tbody>
    </table>
  );
};
