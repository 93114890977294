import { useQuery } from "react-query";
import { useCallback } from "react";
import axios from "axios";

export function useLoadPeriodicReport() {
  const queryItem = useCallback(async () => {
    const api = process.env.REACT_APP_API_URI;
    const { data: { report } } = await axios.get(api + "/report/periodic");
    return report || {};
  }, []);

  return useQuery<any, Error>("useLoadPeriodicReport", queryItem, {
    refetchOnWindowFocus: false,
    initialData: {  },
  });
}
