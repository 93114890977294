import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Waterfall } from "@ant-design/plots";
import { Card } from "antd";
import { useLoadPerformanceReport } from "./hooks/loadPerformanceReport";
import { toNiceNumber } from "@cosmopolis/common";

export const AumChangeChart = () => {
  const { data: report, isLoading, isFetching } = useLoadPerformanceReport();
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    if (report.length === 0) {
      return;
    }
    const data: any = [];
    const parsedReport = report.map((item) => ({
      createdAt: `${item.datetime.substring(0, 10)}}`,
      aum: item.aum,
      totalValue: Math.floor(item.aum),
    }));
    parsedReport.forEach(
      (val, index) => {
        const a = parsedReport[index > 0 ? index - 1 : 0];
        const b = val;
        const item = {
          aum: b.totalValue,
          createdAt: b.createdAt,
          totalValue: b.totalValue - a.totalValue,
        };

        index > 0 && data.push(item);

        return item;
      },
      {
        createdAt: parsedReport[0].createdAt,
        totalValue: parsedReport[0].totalValue,
      }
    );

    setData(data.slice(-30));
  }, [report]);

  const config = {
    data,
    xField: "createdAt",
    yField: "totalValue",
    appendPadding: [15, 0, 0, 0],
    meta: {
      createdAt: {
        alias: "Date",
      },
      totalValue: {
        alias: "AUM",
        formatter: (v) => `$${toNiceNumber(v)}`,
      },
    },
    label: {
      style: {
        fontSize: 10,
        fill: "rgba(0,0,0,0.65)",
      },
      layout: [
        {
          type: "interval-adjust-position",
        },
      ],
    },
    total: {
      label: "Sum",
      style: {
        fill: "#96a6a6",
      },
    },
    risingFill: "#30bf78",
    fallingFill: "#f4664a",
  };
  return (
    <Card loading={isLoading || isFetching} title="Daily AUM Change Last 30 Days">
      <Waterfall {...config} legend={false} />
    </Card>
  );
};
