import axios from "axios";
import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";

export function useResetWorkflow() {
  const client = useQueryClient();

  const { mutate, isLoading } = useMutation(
    async (values: any) => {
      const api = process.env.REACT_APP_API_URI;
      const { workflow } = values;
      console.log("Reset", workflow)
      return axios.post(api + `/workflow/reset`, {
        workflow
      });
    },
    {
      onSuccess: (data) => {
        message.success("Workflow state was reset");
      },
      onError: (ex: any) => {
        message.error(ex?.message);
      },
      onSettled: () => {
        client.invalidateQueries("listExecutionLocks");
      },
    }
  );

  return { mutate, isLoading };
}
