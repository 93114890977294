import { useQueryClient } from "react-query";
import { message } from "antd";
import { useDatabase } from "../../../hooks";
import { useCallback } from "react";
import {
  getDocs,
  collection,
  query,
  where,
  updateDoc,
  deleteField,
} from "firebase/firestore";
import axios from "axios";

export const useRegisterPendingPosition = () => {
  const client = useQueryClient();

  const action = useCallback(async (values) => {
    try {
      const api = process.env.REACT_APP_API_URI;
      await axios.post(api + "/balancer/position", values);
      message.success(`Added pending position`);
      client.refetchQueries("loadPendingPositions");
      client.refetchQueries("loadRequiredBalance");
    } catch (ex: any) {
      message.error(ex.message);
    }
  }, []);

  return { action };
};
