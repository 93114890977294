import { useQueryClient, useMutation } from "react-query";
import axios from "axios";
import { useCallback } from "react";
import { message } from "antd";

export function useGenerateReport() {
  const client = useQueryClient();
  const { mutate, isLoading } = useMutation(
    async () => {
      const api = process.env.REACT_APP_API_URI;
      console.log("API", api);
      return axios.post(api + "/report/status", {});
    },
    {
      onSuccess: (data) => {},
      onError: (ex: any) => {
        message.error(ex?.message);
      },
      onSettled: () => {
        client.invalidateQueries("loadReport");
      },
    }
  );

  return { mutate, isLoading };
}
