import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";

export const CurrentUserContext = React.createContext<{
  user: any;
  loaded: boolean;
}>({ user: null, loaded: false });

export const CurrentUser = ({ children }: { children: any }) => {
  const [user, setUser] = useState<any>();
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    getAuth().onAuthStateChanged(async (userResult: any) => {
      if (userResult) {
        setUser(userResult);
      } else if (userResult !== user) {
        setUser(userResult);
      }
      setLoaded(true);
    });
  }, []);

  return (
    <CurrentUserContext.Provider value={{ user, loaded }}>
      {children}
    </CurrentUserContext.Provider>
  );
};
