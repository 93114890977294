import { useQueryClient } from "react-query";
import { message } from "antd";
import { useDatabase } from "../../../hooks";
import { useCallback } from "react";
import {
  getDocs,
  collection,
  query,
  where,
  updateDoc,
  deleteField,
} from "firebase/firestore";

export const useDeletePendingPosition = ({
  portfolioId,
  asset,
  timestamp,
}: {
  portfolioId: string;
  asset: string;
  timestamp: number;
}) => {
  const client = useQueryClient();
  const database = useDatabase();

  const action = useCallback(async () => {
    if (database) {
      try {
        const ordersQuery = query(
          collection(database, "orders-log"),
          where("info.ref", "==", timestamp),
          where("info.parsed", "!=", true)
        );
        const orders = await getDocs(ordersQuery);
        if (orders.size > 0) {
          message.error(
            "Can't remove pending position if it has unprocessed orders"
          );
          return;
        }

        const ref = collection(database, "pending-positions");
        const execQuery = query(
          ref,
          where("asset", "==", asset),
          where("portfolioId", "==", portfolioId)
        );
        const docs = await getDocs(execQuery);
        if (docs.size > 0) {
          const doc = docs.docs[0];
          const key = `pending.${timestamp}`;
          await updateDoc(doc.ref, {
            [key]: deleteField(),
          });
          message.success(`Removed pending position ${timestamp}`);
          client.refetchQueries("loadPendingPositions");
          client.refetchQueries("loadRequiredBalance");
        } else {
          message.error("No pending position found");
        }
      } catch (ex: any) {
        console.log(ex);
        message.error("Failed to remove pending position: " + ex.message);
      }
    }
  }, [database, client, timestamp, asset, portfolioId]);

  return { action };
};
