import {
  Button,
  Col,
  Layout,
  Row,
  Space,
  Spin,
  Tabs,
  DatePicker,
  Card,
  Table,
  Badge,
  Typography,
  Divider,
  Statistic
} from "antd";
import { useEffect, useState } from "react";
import { useLoadPeriodicReport } from "./hooks/loadPeriodicReport";
import { Page } from "../common";
import { toNiceNumber } from "@cosmopolis/common";
import { green , red, yellow} from '@ant-design/colors'
import { DualAxes } from "@ant-design/plots";
import { minBy } from "lodash";
import { RevenueChart } from "./RevenueChart";

function formatDate(date){
  return `${date.substring(4,6)}/${date.substring(6,8)}`
}

export function PeriodicReportPage() {
  const { data: report, isFetching } = useLoadPeriodicReport();
  console.log(report)

  const title =   report.start ? `Periodic Report ${formatDate(report.start)}-${formatDate(report.end)}` : "Periodic Report";

  return (
    <Page>
      <Layout.Header
        style={{
          backgroundColor: "transparent",
          padding: 0,
          marginTop: 0,
          height: "auto",
        }}
      >
        <Row>
          <Col flex={1}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                lineHeight: 0,
                marginBottom: 24,
                alignItems: "baseline",
              }}
            >
              <div style={{ marginRight: "18px" }}>
               
              </div>
            </div>
          </Col>
          <Col>
</Col>    <Col>
</Col>
        </Row>
      </Layout.Header>
      <Card  bordered={false} loading={isFetching} style={{width: 1200, margin: "auto"}}>
      <Typography.Title level={1}>
                  {title}
                </Typography.Title>
        <Row>
          <Col span={24}>
<br />
          </Col>
        </Row>
        <Row>
          <Col span={6}>
          <Statistic title="Net PnL Change" value={report?.revenueReport?.summary?.pnlChange} precision={2} />
</Col>
<Col span={6}>
<Statistic title="Period Interest" value={report?.revenueReport?.aum?.periodInterest} precision={2} />

          </Col>
          <Col span={6}>
            <Statistic title="Mark-to-Market Revenue" value={report?.revenueReport?.totalUsd} precision={2} />
          </Col>
          <Col span={6}>
            <Statistic title="Ending AUM" value={report?.current?.summary?.totalValue} precision={2} />
            <Statistic title="Starting AUM" value={report?.previous?.summary?.totalValue} precision={2} />
          </Col>
        </Row>
        <Row>
  <Col span={24}>
  <br />
  <Divider />

        <Typography.Title level={2}>AUM & PnL Change</Typography.Title>
        <Divider />
        </Col>

        </Row>
<Row>
  <Col span={24}>
        {report.performance && <AumPnlChart report={report}/>}
        </Col>
        </Row>
        <Row>
  <Col span={24}>
  <br />

  <Typography.Title level={2}>Daily Revenue vs Interest</Typography.Title>
  <Divider />
</Col>
        </Row>
        <Row>
  <Col span={24}>
{report.performance && <RevenueChart report={report.performance}/>}
</Col>
</Row>
<Row>
  <Col span={24}>
  <br />

<Typography.Title level={2}>Spot Value & Amount Change</Typography.Title>
</Col>
</Row>
<Row>
  <Col span={24}>
        <SpotReport report={report} />
</Col>
</Row>
      </Card>
    </Page>
  );
}

const SpotReport = ({ report }) => {
  const dataSource = report?.spot?.assets.filter((asset) => asset.totalValue > 0 && asset.currentPrice*Math.abs(asset.currentAmount) > 100).sort((left, right)=>right.totalValueChange - left.totalValueChange);
const columns = [
  {
    title: 'Symbol',
    dataIndex: 'symbol',
    key: 'symbol',
  },
  {
    title: 'Price (+change)',
    dataIndex: 'currentPrice',
    key: 'currentPrice',
    render: (val, item)=>`$${toNiceNumber(val)} [${toNiceNumber(item.priceChange)}]`
  },
  {
    title: 'Value',
    dataIndex: 'totalValue',
    key: 'totalValue',
    render: (val, item)=>`$${toNiceNumber(Math.abs(val))}`
  },
  {
    title: 'Amount (+change)',
    dataIndex: 'currentAmount',
    key: 'currentAmount',
    render: (val, item)=><>{toNiceNumber(val)}{' '}{item.amountChange !== 0 ? `[${toNiceNumber(item.amountChange)}]`: ""}</>
  },
  {
    title: 'Amount Value Change',
    dataIndex: 'amountValueChange',
    key: 'amountValueChange',
    render: (val, item)=>`$${toNiceNumber(val)}`

  },
  {
    title: 'Base Value Change',
    dataIndex: 'baseValueChange',
    key: 'baseValueChange',
    render: (val, item)=>`$${toNiceNumber(val)}`

  },
  {
    title: 'Total Value Change',
    dataIndex: 'totalValueChange',
    key: 'totalValueChange',
    render: (val, item)=>`$${toNiceNumber(val)}`

  },
];

const Text = Typography.Text;

return <>
<style>
 { `
  .amountUp{
    background-color: ${green[2]};
  }
  .valueUp{
    background-color: ${green[0]};
  }
  .amountDown {
    background-color: ${red[2]};
  }
  .valueDown {
    background-color: ${red[0]};
  }
  `}
</style>
<Card bordered={false} extra={<Space><Badge color={green[5]} text="+ amount change" /><Badge color={red[5]} text="- amount change" /><Badge color={green[2]} text="+ value change" /><Badge color={red[2]} text="- value change" /></Space>}>
<Table rowClassName={(item)=>setColor(item.amountChange, item.totalValueChange)} pagination={false} size={"small"} dataSource={dataSource} columns={columns}

summary={(page)=><><Table.Summary.Row style={{backgroundColor: yellow[1]}}>
  <Table.Summary.Cell colSpan={6} index={0} align="left">Total spot amount change</Table.Summary.Cell>
  <Table.Summary.Cell index={1} colSpan={1}   >
   <Text italic> {`$${toNiceNumber(page.reduce((sum, item)=>sum+item.amountValueChange, 0))}`}</Text>
  </Table.Summary.Cell>
</Table.Summary.Row>
<Table.Summary.Row style={{backgroundColor: yellow[1]}} >
  <Table.Summary.Cell colSpan={6} index={0} align="left"> <Text strong>Total spot value change</Text></Table.Summary.Cell>
  <Table.Summary.Cell index={1} colSpan={1}  >
  <Text strong>{`$${toNiceNumber(page.reduce((sum, item)=>sum+item.totalValueChange, 0))}`}</Text>
  </Table.Summary.Cell>
</Table.Summary.Row>
</>}>

</Table>

</Card>
</>;
}

function setColor(amountChange, valueChange){
  let color = "";

  if(valueChange>0) { 
    color = "valueUp"
  }
  if(valueChange<0){
    color = "valueDown"
  }
  if(amountChange>0){
    color = "amountUp"
  } 
  if(amountChange<0){
    color = "amountDown"
  }
  return color;
}

export const AumPnlChart = ({ report }) => { 
  const aum = report && report.performance ? report.performance?.map((item) => ({ datetime: item.datetime, aum: item.aum, borrowed: Math.abs(item.totalBorrowed), pnl: item.pnl })) : [];
  let aumMin =minBy(aum, (item: any)=>item.aum).aum
  aumMin = aumMin-(aumMin*0.1)
  const config = {
    data: [aum, aum],
    xField: 'datetime',
    yField: ["aum", 'pnl'],
    yAxis: {
      aum: {min:aumMin}
    },
    geometryOptions: [
      {
        geometry: 'column',
        color: '#5B8FF9',
        columnWidthRatio: 0.4,
        label: {
          position: 'middle',
        },
      },
      {
        geometry: 'line',
        smooth: true,
        color: '#5AD8A6',
      },
    ],
    interactions: [
      {
        type: 'element-highlight',
      },
      {
        type: 'active-region',
      },
    ],
    meta: {
      datetime: {
        alias: "Date",
        formatter: (date) => `${date.substring(0,10)}`,

      },
      pnl: {
        alias: "PnL",
        formatter: (v) => `$${toNiceNumber(v)}`,
      },
      aum: {
        alias: "AUM",
        formatter: (v) => `$${toNiceNumber(v)}`,
      },
    },
   
  } as any
  return <Card bordered={false} >
  <DualAxes {...config} />
  </Card>
}