import { useQuery } from "react-query";
import { useCallback } from "react";
import { sortBy } from "lodash";
import axios from "axios";
import { Trade } from "../../../common";

export function useLoadTrades({ live }) {
  const refetchInterval = live ? 1000 : 30000;
  const queryItem = useCallback(async () => {
    const api = process.env.REACT_APP_API_URI;
    const { data } = await axios.get(api + "/cex/ftx/treasury/trades");
    return data.trades;
  }, []);

  return useQuery<Trade[], Error>("loadTrades", queryItem, {
    refetchInterval,
    initialData: [],
  });
}
