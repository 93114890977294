import { getFirestore } from "firebase/firestore";
import { useFirebase } from ".";
import { useMemo } from "react";

export const useDatabase = () => {
  const firebase = useFirebase();
  const db = useMemo(() => {
    if (firebase) {
      return getFirestore();
    }
  }, [firebase]);
  return db;
};
