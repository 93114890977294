import { useDatabase } from "../../../hooks";
import { useQuery } from "react-query";
import {
  getDocs,
  doc,
  collection,
  query,
  orderBy,
  limit,
  getDoc,
  where,
} from "firebase/firestore";
import { useCallback, useState } from "react";

export function useLoadCurrentPositions({
  portfolioId,
}: {
  portfolioId: string;
}) {
  const database = useDatabase();

  const queryItem = useCallback(async () => {
    if (database) {
      const ref = collection(database, "hedger-positions");
      const execQuery = query(ref, where("portfolioId", "==", portfolioId));
      const docs = await getDocs(execQuery);
      const items = await Promise.all(
        docs.docs.map((doc) => doc.data() as RequiredPortfolioItem)
      );
      return items;
    }
    return [];
  }, [database, portfolioId]);

  return useQuery<RequiredPortfolioItem[], Error>(
    "loadCurrentPositions",
    queryItem,
    {
      initialData: [],
    }
  );
}

export type RequiredPortfolioItem = {
  items: { [asset: string]: number };
  strategyId: string;
  portfolioId: string;
  log: { asset: string; timestamp: number; amount: number }[];
};
