import {
  getAuth,
  signInWithRedirect,
  GoogleAuthProvider,
  getRedirectResult,
  signInWithPopup
} from "firebase/auth";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

export const LoginPage = () => {
  const auth = useMemo(() => getAuth(), []);
  let history = useNavigate();
  useEffect(() => {
    getRedirectResult(auth)
      .then((result) => {
        if (result === null) {
          signInWithPopup(auth, new GoogleAuthProvider()).then((user)=>{
            if(user){
              history("/portfolio/status");
            } else {
              throw Error("Auth did not return valid user")
            }
          }).catch(ex=> alert(ex.message))
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, []);
  return <div>Login</div>;
};
