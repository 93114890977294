import { Card, Table, Col, Row, Button } from "antd";
import { formatDistanceToNow } from "date-fns";
import { PendingPosition } from "@cosmopolis/balancer";
import { Descriptions } from "antd";
import { useGetOrdersByPendingPosition } from "./hooks/getOrdersByPendingPosition";
import { useDeletePendingPosition, useParsePendingPosition } from "./hooks";
const { Meta } = Card;

export function PendingPositionItem({ item }: { item: PendingPosition }) {
  const submitted = formatDistanceToNow(new Date(item.timestamp));
  const { data } = useGetOrdersByPendingPosition({
    timestamp: item.timestamp,
  });
  const { parsed, total } = data!;
  const { action: deleteItem } = useDeletePendingPosition({
    asset: item.asset,
    portfolioId: item.portfolioId,
    timestamp: item.timestamp,
  });
  const { mutate, isLoading } = useParsePendingPosition();
  let extra = [<div onClick={deleteItem}>Delete</div>];

  console.log(item);
  if (item.status || total > 0) {
    extra = [
      <Button
        loading={isLoading}
        onClick={() =>
          mutate({
            timestamp: item.timestamp,
            asset: item.asset,
            portfolioId: item.portfolioId,
          })
        }
      >
        Parse
      </Button>,
    ];
  }
  return (
    <Card
      style={{ marginTop: 16 }}
      title={`${item.asset} ${item.legType} - ${item.amount}`}
      extra={item.status}
      actions={extra}
      bodyStyle={{ padding: 0 }}
    >
      <Descriptions bordered layout="vertical">
        <Descriptions.Item label="Strategy">
          {item.strategyId}
        </Descriptions.Item>
        <Descriptions.Item label="Orders (parsed/total)">
          {parsed}/{total}
        </Descriptions.Item>
        <Descriptions.Item label="Submitted">{submitted} ago</Descriptions.Item>
      </Descriptions>
    </Card>
  );
}

//
