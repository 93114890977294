import {
  Button,
  Col,
  Layout,
  Row,
  Space,
  Spin,
  Tabs,
  Typography,
  DatePicker,
} from "antd";
import { useEffect, useState } from "react";

import { Page } from "../common";
import { StatusReport } from "./StatusReport";
import { StrategyReport } from "./StrategyReport";
import { formatRelative } from "date-fns";
import { useGenerateReport } from "./hooks/generateReport";
import { useLoadReport } from "./hooks/loadReport";

export function StatusReportPage() {
  const [skip, setSkip] = useState(1);
  const [timestamp, setTimestamp] = useState(0);

  const { data, error, refetch, isFetching, isError } = useLoadReport({
    skip,
    timestamp: timestamp * 1000,
  });
  const { mutate, isLoading: generateReportLoading } = useGenerateReport();
  const isLoading = isFetching;
  useEffect(() => {
    refetch();
  }, [skip]);

  return (
    <Page>
      <Layout.Header
        style={{
          backgroundColor: "transparent",
          padding: 0,
          marginTop: 0,
          height: "auto",
        }}
      >
        <Row>
          <Col flex={1}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                lineHeight: 0,
                marginBottom: 24,
                alignItems: "baseline",
              }}
            >
              <div style={{ marginRight: "18px" }}>
                <Typography.Title level={1}>Status Report</Typography.Title>
              </div>
              <div>
                {isLoading && !isError && <Spin />}
                {error && (
                  <Typography.Text type="danger">
                    {error.message}
                  </Typography.Text>
                )}
                {data && data.report && !isFetching && (
                  <Typography.Text type="secondary">
                    Updated{" "}
                    {formatRelative(
                      new Date(data.report.timestamp),
                      new Date()
                    )}
                  </Typography.Text>
                )}
              </div>
            </div>
          </Col>
          <Col>
            <Space>
              <DatePicker showTime onOk={(data) => setTimestamp(data.unix())} />
              <Button onClick={() => setSkip(skip + 1)}>Previous</Button>
              <Button disabled={skip <= 0} onClick={() => setSkip(skip - 1)}>
                Next
              </Button>
              <Button
                loading={generateReportLoading}
                onClick={() => mutate()}
                type={"primary"}
              >
                Generate
              </Button>
            </Space>
          </Col>
        </Row>
      </Layout.Header>
      <Tabs
        defaultActiveKey="1"
        tabBarStyle={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
        items={[
          {
            key: "1",
            label: "Summary",
            children: <StatusReport report={data?.report} />,
          },
          {
            key: "2",
            label: "Strategies",
            children: <StrategyReport report={data?.report} />,
          },
        ]}
      ></Tabs>
    </Page>
  );
}
