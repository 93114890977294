import { Card, Table, Alert, Button, Spin, Row, Col, Space } from "antd";
import type { ColumnsType } from "antd/es/table";
import { formatDistance } from "date-fns";
import { useLoadTrades } from "./hooks/loadTrades";
import { Trade } from "../../common";

import { toNiceNumber } from "@cosmopolis/common";

export function TradesTable({ live }: { live?: boolean }) {
  const { isLoading, isFetching, data, isError, refetch, error } =
    useLoadTrades({ live });

  const columns: ColumnsType<Trade> = [
    {
      title: "Time",
      dataIndex: "datetime",
      key: "datetime",
      render: (val) => `${formatDistance(new Date(val), new Date())} ago`,
      sorter: (a, b) => b.timestamp - a.timestamp,
      defaultSortOrder: "ascend",
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      render: (val) => toNiceNumber(val.cost),
    },
    {
      title: "Fee",
      dataIndex: "fee",
      key: "fee",
      render: (val) => toNiceNumber(val.cost),
    },
  ];

  return (
    <Card
      title={
        <Space>
          Last 100 trades in Treasury account {isFetching && <Spin />}
        </Space>
      }
      loading={isLoading}
      extra={[<Button onClick={() => refetch()}>Refresh</Button>]}
    >
      {isError && (
        <Alert
          message="Error loading trades"
          description={error?.message}
          type="error"
          action={
            <Button size="small" danger onClick={() => refetch()}>
              Retry
            </Button>
          }
        />
      )}
      <Table
        pagination={{ pageSize: 25 }}
        size={"small"}
        columns={columns}
        dataSource={data}
      />
    </Card>
  );
}
