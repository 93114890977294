import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Line } from "@ant-design/plots";
import { Card } from "antd";
import { useLoadPerformanceReport } from "./hooks/loadPerformanceReport";
import { toNiceNumber } from "@cosmopolis/common";
import { maxBy, minBy } from "lodash";

export const PnlChart = () => {
  const { data: report, isLoading, isFetching } = useLoadPerformanceReport();
  const [data, setData] = useState<any>([]);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(10000000);

  useEffect(() => {
    if (report.length === 0) {
      return;
    }
    
    const parsedReport = report.map((item) => ({
      createdAt: `${item.datetime.substring(0, 10)}`,
      pnl: Math.floor(item.pnl),
    }));

    try{
        const maxPnl: any= maxBy<any>(parsedReport, ({pnl})=>pnl as number).pnl;
        const minPnl: any = minBy<any>(parsedReport, ({pnl})=>pnl as number).pnl;
        console.log(minPnl)
        setMax(maxPnl+maxPnl*0.05);
       setMin(minPnl-minPnl*0.05);
    } catch(ex){
        console.log(ex);
    }
   

    setData(parsedReport);
  }, [report]);

  const config = {
    data,
    
    xField: "createdAt",
    yField: "pnl",
    appendPadding: [15, 0, 0, 0],
    meta: {
      createdAt: {
        alias: "Date",
      },
      pnl: {
        alias: "PnL",
        formatter: (v) => `$${toNiceNumber(v)}`,
      },
    },
    label: {
      style: {
        fontSize: 10,
        fill: "rgba(0,0,0,0.65)",
      },
      
    },
   
    
  };
  return (
    <Card loading={isLoading || isFetching} title="PnL">
      <Line {...config} legend={false}  yAxis={{minLimit: min, maxLimit: max}} />
    </Card>
  );
};
