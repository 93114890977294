import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
} from "antd";
import { LegTypes, Strategies } from "../../common";

import { useCallback } from "react";
import { useCurrentPortfolioId } from "../../hooks";
import { useRegisterTransaction } from "./hooks";

export const RegisterTransactionForm = () => {
  const [form] = Form.useForm();

  const { mutate, isLoading } = useRegisterTransaction();

  const { portfolioId } = useCurrentPortfolioId();
  const onFinish = useCallback(
    (values) => {
      const payload = {
        strategyId: values.strategyId,
        portfolioId,
        asset: values.asset,
        legType: values.legType,
        amount: values.amount,
        price: values.price,
        priceInBase: values.priceInBase,
        timestamp: values.timestamp,
        txHash: values.txHash,
        note: values.note,
      };
      mutate(payload);
    },
    [portfolioId, mutate]
  );

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Card style={{ maxWidth: "600px" }} title="Register Transaction">
      <Form
        form={form}
        size="large"
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Leg Type"
          name="legType"
          rules={[{ required: true, message: "Please input leg typ" }]}
        >
          <Select
            showSearch
            options={Object.keys(LegTypes).map((key) => ({
              value: LegTypes[key],
              label: key,
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Strategy"
          name="strategyId"
          rules={[{ required: true, message: "Please input leg typ" }]}
        >
          <Select
            showSearch
            options={Object.keys(Strategies).map((key) => ({
              value: Strategies[key],
              label: key,
            }))}
          />
        </Form.Item>

        <Form.Item
          label="Asset"
          name="asset"
          rules={[{ required: true, message: "Please input asset" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Amount"
          name="amount"
          rules={[{ required: true, message: "Please input amount" }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Price"
          name="price"
          rules={[{ required: true, message: "Please input price" }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label="Price In Base" name="priceInBase">
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Date"
          name="timestamp"
          rules={[{ required: true, message: "Please input date and time" }]}
        >
          <DatePicker showTime />
        </Form.Item>
        <Form.Item
          label="Transaction id"
          name="txHash"
          rules={[{ required: true, message: "Please input transaction id" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Note" name="note">
          <Input.TextArea rows={4} maxLength={1000} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
          <Space>
            <Button htmlType="button" onClick={onReset}>
              Reset
            </Button>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
};
