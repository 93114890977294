import { useDatabase } from "../../../hooks";
import { useQuery } from "react-query";
import { getDocs, collection, query, where } from "firebase/firestore";
import { useCallback } from "react";
import { PendingPosition } from "@cosmopolis/balancer";
import { sortBy } from "lodash";

export function useLoadPendingPositions({
  portfolioId,
}: {
  portfolioId: string;
}) {
  const database = useDatabase();

  const queryItem = useCallback(async () => {
    if (database) {
      const ref = collection(database, "pending-positions");
      const execQuery = query(ref, where("portfolioId", "==", portfolioId));
      const docs = await getDocs(execQuery);
      const items = await Promise.all(docs.docs.map((doc) => doc.data()));
      let pending: PendingPosition[] = [];

      items.forEach((item) => {
        pending = pending.concat(Object.values(item.pending));
      });

      pending = sortBy(pending, (p) => p.timestamp, "desc");
      return pending;
    }
    return [];
  }, [database, portfolioId]);

  return useQuery<PendingPosition[], Error>("loadPendingPositions", queryItem, {
    initialData: [],
  });
}
