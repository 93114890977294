import { Card, Table, Alert, Button, Spin, Row, Col, Space, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { formatDistance } from "date-fns";
import { useLoadOrders } from "./hooks/loadOrders";
import { Order } from "../../common";

import { toNiceNumber } from "@cosmopolis/common";

export function OrdersTable({ live }: { live?: boolean }) {
  const { isLoading, isFetching, data, isError, refetch, error } =
    useLoadOrders({ live });
  const getStatusTag = (status) => {
    let color = "green";
    if (status === "canceled") {
      color = "red";
    } else if (status === "open") {
      color = "blue";
    }
    return <Tag color={color}>{status}</Tag>;
  };

  const columns: ColumnsType<Order> = [
    {
      title: "Time",
      dataIndex: "datetime",
      key: "datetime",
      render: (val) => `${formatDistance(new Date(val), new Date())} ago`,
      sorter: (a, b) => b.timestamp - a.timestamp,
      defaultSortOrder: "ascend",
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      title: "Amount",
      key: "amount",
      render: (val) => `${val.filled}/${val.amount}`,
    },
    {
      title: "Price",
      key: "price",
      dataIndex: "price",
    },
    {
      title: "Average Price",
      key: "average",
      dataIndex: "average",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (val) => getStatusTag(val),
    },
  ];

  return (
    <Card
      title={
        <Space>
          Last 100 orders in Treasury account {isFetching && <Spin />}
        </Space>
      }
      loading={isLoading}
      extra={[<Button onClick={() => refetch()}>Refresh</Button>]}
    >
      {isError && (
        <Alert
          message="Error loading orders"
          description={error?.message}
          type="error"
          action={
            <Button size="small" danger onClick={() => refetch()}>
              Retry
            </Button>
          }
        />
      )}
      <Table
        pagination={{ pageSize: 25 }}
        size={"small"}
        columns={columns}
        dataSource={data}
      />
    </Card>
  );
}
