import { Page } from "../common";
import { Layout, Tabs, Row, Col, Typography, Space, Spin, Button } from "antd";
import { formatRelative } from "date-fns";
import { useState, useEffect } from "react";
import { WorkflowList } from "./WorkflowList";

export function WorkflowPage() {
 
  return (
    <Page>
      <Layout.Header
        style={{
          backgroundColor: "transparent",
          padding: 0,
          marginTop: 0,
          height: "auto",
        }}
      >
        <Row>
          <Col flex={1}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                lineHeight: 0,
                marginBottom: 24,
                alignItems: "baseline",
              }}
            >
              <div style={{ marginRight: "18px" }}>
                <Typography.Title level={1}>Workflows</Typography.Title>
              </div>
              <div>
                
              </div>
            </div>
          </Col>
          <Col></Col>
        </Row>
      </Layout.Header>
      <Tabs
        defaultActiveKey="1"
        tabBarStyle={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
      >
        <Tabs.TabPane tab="List" key="1">
          <WorkflowList />
        </Tabs.TabPane>
       
      </Tabs>
    </Page>
  );
}
