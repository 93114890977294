import * as firebase from "firebase/app";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { useEffect, useState } from "react";
import { log } from "../service";
import { getAuth, connectAuthEmulator } from "@firebase/auth";

export const useLoadFirebase = () => {
  const [loaded, setLoaded] = useState<any>();

  useEffect(() => {
    if (firebase.getApps().length === 0) {
      try {
        log.debug("Initializing firebase...");
        firebase.initializeApp({
          apiKey: process.env.REACT_APP_API_KEY,
          authDomain: process.env.REACT_APP_AUTH_DOMAIN,
          databaseURL: process.env.REACT_APP_DATABASE_URL,
          projectId: process.env.REACT_APP_PROJECT_ID,
          storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
          messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
          appId: process.env.REACT_APP_APP_ID,
          measurementId: process.env.REACT_APP_MEASUREMENT_ID,
        });
        if (process.env.NODE_ENV === "development") {
          console.log("Setting firebase emulators");

          setupEmulators(firebase);
        }
        setLoaded(true);
        log.debug("Firebase initialized");
      } catch (ex) {
        log.error("Error initializing firebase", ex);
      }
    }
  }, []);

  return { loaded };
};

function setupEmulators(firebase: any) {
  //firebase.database().useEmulator("localhost", 9000);
  // firebase.auth().useEmulator("http://localhost:9099");
  //const auth = getAuth(firebase.getApp());
  // connectAuthEmulator(auth, "http://localhost:9099");
  // console.log(auth);
  // console.log(firebase.getApp());
  const functions = getFunctions(firebase.getApp());
  //functions.useFunctionsEmulator("http://localhost:5001");
  connectFunctionsEmulator(functions, "localhost", 5001);
  // const firestore = getFirestore(firebase.getApp());
  // connectFirestoreEmulator(firestore, "localhost", 8080);
  //firebase.storage().useEmulator("localhost", 9199);
  //firebase.firestore().useEmulator("localhost", 8080);
}
