import { Row, Col } from "antd";

import { TradesTable } from "./TradesTable";
import { OrdersTable } from "./OrdersTable";

export function OrdersPane({ live }: { live?: boolean }) {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24} xl={{ span: 12 }}>
        <TradesTable live={live} />
      </Col>
      <Col span={24} xl={{ span: 12 }}>
        <OrdersTable live={live} />
      </Col>
    </Row>
  );
}
