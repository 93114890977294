export enum Chain {
  Ethereum = "1",
  Base = "8453",
  Terra = "1000000",
  Fantom = "250",
  Matic = "137",
  Bsc = "56",
  Avalanche = "43114",
  Arbitrum = "42161",
  BinanceEx = "BinanceEx",
  Polygon = "137",
  Cex = "Cex",
  NA = "0",
}

export enum Exchange {
  Binance = "Binance",
}

// Used for gaslimit value
export const gasLimit = 10000000;
