import {
  Button,
  Col,
  Layout,
  Row,
  Space,
  Spin,
  Tabs,
  Typography,
  DatePicker,
  
} from "antd";
import { useEffect, useState } from "react";
import { AumChangeChart } from "./AumChangeChart";
import { AumChart } from "./AumChart";
import { PnlChart } from "./PnlChart"

import { Page } from "../common";
import { RevenueChart } from "./RevenuChart";

export function PerformanceReportPage() {
  return (
    <Page>
      <Layout.Header
        style={{
          backgroundColor: "transparent",
          padding: 0,
          marginTop: 0,
          height: "auto",
        }}
      >
        <Row>
          <Col flex={1}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                lineHeight: 0,
                marginBottom: 24,
                alignItems: "baseline",
              }}
            >
              <div style={{ marginRight: "18px" }}>
                <Typography.Title level={1}>
                  Performance Report
                </Typography.Title>
              </div>
            </div>
          </Col>
          <Col></Col>
        </Row>
      </Layout.Header>
      <RevenueChart />
      <br />

      <PnlChart />
      <br />

      <AumChart />
      <br />

      <AumChangeChart />
    </Page>
  );
}
