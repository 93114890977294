import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { BalancerPage } from "../balancer";
import { BorrowedCapitalPage } from "../borrowed-capital";
import { Home } from "../home";
import { LoadingPage } from "./LoadingPage";
import { LoginPage } from "../login";
import { PerformanceReportPage } from "../performance-report";
import { ProtectedRoute } from "./ProtectedRoute";
import { RestrictedPage } from "./RestrictedPage";
import { StatusReportPage } from "../status-report";
import { TransactionsPage } from "../portfolio";
import { WorkflowPage } from "../workflow";
import { PeriodicReportPage } from "../periodic-report";
import { useCurrentUser } from "../../hooks";

export const AppRouter = () => {
  const { loaded } = useCurrentUser();
  if (!loaded) {
    return <LoadingPage />;
  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/restricted" element={<RestrictedPage />} />
       <Route
          path="/balancer"
          element={
            <ProtectedRoute>
              <BalancerPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/portfolio/status"
          element={
            <ProtectedRoute>
              <StatusReportPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/portfolio/performance"
          element={
            <ProtectedRoute>
              <PerformanceReportPage />
            </ProtectedRoute>
          }
        />
         <Route
          path="/portfolio/periodic"
          element={
            <ProtectedRoute>
              <PeriodicReportPage />
            </ProtectedRoute>
          }
        /> 
        <Route
          path="/portfolio/transactions"
          element={
            <ProtectedRoute>
              <TransactionsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/borrow"
          element={
            <ProtectedRoute>
              <BorrowedCapitalPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/workflow"
          element={
            <ProtectedRoute>
              <WorkflowPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <StatusReportPage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};
