import { useQuery } from "react-query";
import { useCallback } from "react";
import axios from "axios";

export function useLoadRequiredBalance() {
  const queryItem = useCallback(async () => {
    const api = process.env.REACT_APP_API_URI;
    const { data } = await axios.get(api + "/balancer/required");
    return data || {};
  }, []);

  return useQuery<{ [asset: string]: number }, Error>(
    "loadRequiredBalance",
    queryItem,
    {
      initialData: {},
    }
  );
}
