import React from "react";
import { Route, Navigate } from "react-router-dom";
import { useCurrentUser } from "../../hooks/currentUser";

export function ProtectedRoute(props) {
  const { user, loaded } = useCurrentUser();
  console.log("USDR", user, loaded)
  const redirectTo = props.redirectTo || "/restricted";
  if (loaded && !user) {
    return <Navigate to={redirectTo}></Navigate>;
  } else if (user) {
    return <>{props.children}</>;
  } else {
    return <div>Waiting</div>;
  }
}
