import { Button, Col, Layout, Row, Tabs, Typography } from "antd";

import { Page } from "../common";
import { RegisterTransactionForm } from "./RegisterTransactionForm";

export const TransactionsPage = () => {
  return (
    <Page>
      <Layout.Header
        style={{
          backgroundColor: "transparent",
          padding: 0,
          marginTop: 0,
          height: "auto",
        }}
      >
        <Row>
          <Col flex={1}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                lineHeight: 0,
                marginBottom: 24,
                alignItems: "baseline",
              }}
            >
              <div style={{ marginRight: "18px" }}>
                <Typography.Title level={1}>Transactions</Typography.Title>
              </div>
            </div>
          </Col>
        </Row>
      </Layout.Header>
      <Tabs
        defaultActiveKey="1"
        tabBarStyle={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
        items={[
          {
            key: "1",
            label: "Register",
            children: <RegisterTransactionForm />,
          },
        ]}
      ></Tabs>
    </Page>
  );
};
