import { Chain } from "./misc";
// USDC Proxy and Implementation
export const USDC_PROXY =
  "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48".toLowerCase();
export const USDC_IMPLEMENTATION =
  "0xa2327a938febf5fec13bacfb16ae10ecbc4cbdcf".toLowerCase();

// Compound USDC
export const CUSDC = "0x39aa39c021dfbae8fac545936693ac917d5e7563".toLowerCase();

export const WETH = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2".toLowerCase();

export const USDC_IMP = {
  [Chain.Ethereum]: "0xa2327a938febf5fec13bacfb16ae10ecbc4cbdcf".toLowerCase(),
};

export const AddressBook = {
  DAI: {
    [Chain.Ethereum]:
      "0x6b175474e89094c44da98b954eedeac495271d0f".toLowerCase(),
  },
  USDC: {
    [Chain.Ethereum]:
      "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48".toLowerCase(),
    [Chain.Arbitrum]:
      "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8".toLowerCase(),
  },
  USDT: {
    [Chain.Ethereum]:
      "0xdac17f958d2ee523a2206206994597c13d831ec7".toLowerCase(),
  },
  CRV: {
    [Chain.Ethereum]:
      "0xD533a949740bb3306d119CC777fa900bA034cd52".toLowerCase(),
  },
  CVX: {
    [Chain.Ethereum]:
      "0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b".toLowerCase(),
  },
  WETH: {
    [Chain.Ethereum]:
      "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2".toLowerCase(),
  },
  CVXCRV: {
    [Chain.Ethereum]:
      "0x62B9c7356A2Dc64a1969e19C23e4f579F9810Aa7".toLowerCase(),
  },
  FXS: {
    [Chain.Ethereum]:
      "0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0".toLowerCase(),
  },
  CVXFXS: {
    [Chain.Ethereum]:
      "0xFEEf77d3f69374f66429C91d732A244f074bdf74".toLowerCase(),
  },
  AUSDC: {
    [Chain.Ethereum]:
      "0xBcca60bB61934080951369a648Fb03DF4F96263C".toLowerCase(),
  },
  LUSD: {
    [Chain.Ethereum]:
      "0x5f98805A4E8be255a32880FDeC7F6728C6568bA0".toLowerCase(),
  },
  "3CRV": {
    [Chain.Ethereum]:
      "0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490".toLowerCase(),
  },
  // CRV-ETH Curve Pool LP
  CRVCRVETH: {
    [Chain.Ethereum]:
      "0xed4064f376cb8d68f770fb1ff088a3d0f3ff5c4d".toLowerCase(),
  },
  // LUSD-3CRV Curve Pool LP
  "LUSD3CRV-F": {
    [Chain.Ethereum]:
      "0xed279fdd11ca84beef15af5d39bb4d4bee23f0ca".toLowerCase(),
  },
  "CVXCRV-F": {
    [Chain.Ethereum]:
      "0x9d0464996170c6b9e75eed71c68b99ddedf279e8".toLowerCase(),
  },
  "ETH+ETH-F": {
    [Chain.Ethereum]:
      "0xe8a5677171c87fCB65b76957f2852515B404C7b1".toLowerCase(),
  },
  "ETH+": {
    [Chain.Ethereum]: "0xE72B141DF173b999AE7c1aDcbF60Cc9833Ce56a8".toLowerCase()
  }
};

export function getTokenAddress(symbol: string, chain: Chain) {
  symbol = symbol.toUpperCase();
  if (!AddressBook[symbol]) {
    throw new Error("No such symbol");
  }
  if (!AddressBook[symbol][chain]) {
    throw new Error("Symbol is not known on this chain");
  }
  return AddressBook[symbol][chain].toLowerCase();
}
