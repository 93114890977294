import { useLoadCurrentPositions } from "./hooks";
import { PortfolioItem } from "./PortfolioItem";
import { Col, Row, Typography } from "antd";
import { AddPendingPositionModal } from "./AddPendingPositionModal";
import { useState, useReducer } from "react";

export function StrategiesPane() {
  const { data: requiredPortfolio } = useLoadCurrentPositions({
    portfolioId: "0x62ed28802362bb79ef4cee858d4f7aca5edd0490",
  });
  const [showModal, setShowModal] = useState(false);
  const [formInitialValues, setFormInitialValues] = useReducer(
    (state, action) => {
      return action;
    },
    {
      asset: "",
      amount: 0,
      strategyId: "",
    }
  );
  return (
    <>
      <Row gutter={16}>
        <Col className="gutter-row" md={{ span: 24 }} lg={{ span: 12 }}>
          <Typography.Title level={3}>Current Positions</Typography.Title>
          {requiredPortfolio?.map((item, index) => (
            <PortfolioItem
              key={"required" + index}
              item={item}
              onAdjust={(values) => {
                console.log("Setting", values);
                setFormInitialValues(values);
                setShowModal(true);
              }}
            />
          ))}
        </Col>
      </Row>
      <AddPendingPositionModal
        {...formInitialValues}
        show={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
}
