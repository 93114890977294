import axios from "axios";
import { message } from "antd";
import { useMutation } from "react-query";

export function useRegisterTransaction() {
  const { mutate, isLoading } = useMutation(
    async (values: any) => {
      const api = process.env.REACT_APP_API_URI;
      const { portfolioId } = values;
      return axios.post(api + `/portfolio/${portfolioId}/transaction`, {
        ...values,
      });
    },
    {
      onSuccess: (data) => {
        message.success("Transaction registered");
      },
      onError: (ex: any) => {
        message.error(ex?.message);
      },
      onSettled: () => {
        //client.invalidateQueries("loadReport");
      },
    }
  );

  return { mutate, isLoading };
}
