import { useDatabase } from "../../../hooks";
import { useQuery } from "react-query";
import { getDocs, collection, query, where } from "firebase/firestore";
import { useCallback } from "react";

export function useGetOrdersByPendingPosition({
  timestamp,
}: {
  timestamp: number;
}) {
  const database = useDatabase();
  const queryItem = useCallback(async () => {
    if (database) {
      const ref = collection(database, "orders-log");
      const execQuery = query(ref, where("info.ref", "==", timestamp));
      const docs = await getDocs(execQuery);
      const items = await Promise.all(
        docs.docs.map((doc) => doc.data() as any)
      );
      const filtered = items.filter((item) => item.parsed);
      const parsed = filtered.length;
      const pending = items.length - parsed;
      return { parsed, pending, total: items.length };
    }
    return { parsed: 0, pending: 0, total: 0 };
  }, [database, timestamp]);

  return useQuery<{ parsed: number; pending: number; total: number }, Error>(
    ["getOrders", timestamp],
    queryItem,
    {
      initialData: { parsed: 0, pending: 0, total: 0 },
    }
  );
}
