import { Card, Table, Col, Row, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";

import { RequiredPortfolioItem } from "./hooks";

export function PortfolioItem({
  item,
  onAdjust,
}: {
  item: RequiredPortfolioItem;
  onAdjust: (params: {
    asset: string;
    amount: number;
    strategyId: string;
  }) => void;
}) {
  const data: any = [];
  Object.keys(item.items).forEach((asset) => {
    const [latest, previous] = item.log
      .filter((log) => log.asset === asset)
      .sort((a, b) => b.timestamp - a.timestamp);
    const prevAmount = previous?.amount || 0;
    const latestCaption = `${prevAmount} -> ${latest.amount} ${
      latest.asset
    } at ${new Date(latest.timestamp).toLocaleString()}`;
    data.push({
      asset: asset,
      amount: item.items[asset],
      latest: latestCaption,
    });
  });

  const columns: ColumnsType<any> = [
    {
      title: "Asset",
      dataIndex: "asset",
      key: "asset",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Latest",
      dataIndex: "latest",
      key: "latest",
    },
    {
      title: "Action",
      key: "action",
      render: (val) => (
        <Typography.Link
          onClick={() => {
            return onAdjust({
              asset: val.asset,
              amount: val.amount,
              strategyId: item.strategyId,
            });
          }}
        >
          Adjust
        </Typography.Link>
      ),
    },
  ];
  return (
    <Card
      bodyStyle={{ padding: 0 }}
      style={{ marginTop: 16 }}
      title={item.strategyId}
    >
      <Table pagination={false} columns={columns} dataSource={data} />
    </Card>
  );
}
