import "./report.css";

import { Card, Divider, Typography } from "antd";

import { TinyArea } from "@ant-design/plots";
import { Strategies, toNiceNumber } from "@cosmopolis/common";

function getLegIndex(leg) {
  const { legType } = leg;
  switch (legType) {
    case "Principle":
      return 0;
    case "Hedge":
      return 1;
    case "Lend":
      return 2;
    case "Borrow":
      return 3;
    case "Reward":
      return 4;
    default:
      return 5;
  }
}
export const StrategyReport = ({ report }) => {
  console.log(report)
  if (!report) {
    return <>NO DATA</>;
  }

  const legacyStrategies = ["GMX Arbitrum", "TRADING PORTFOLIO", "IPOR", "STURDY", "CRV-ETH LP", "CURVE-3CRV-BLUSD", "MIM-3CRV LP", "EUSD-3CRV LP", "CVXCRV-CRV LP", "CHICKEN BONDS", "MORPHO", "LOOKS RARE", "GEARBOX", "GEARBOX_CREDIT", "CVXCRV Stake"];
  const strategies = legacyStrategies.concat(Object.values(Strategies));

  const strategyNames = {
    [Strategies.GMX_ARBITRUM]: "GMX Arbitrum",
    [Strategies.EUSD_FRAX_LP]: "Convex EUSD-FRAX",
    [Strategies.TRADING_PORTFOLIO]: "Trading Portfolio",
    [Strategies.IPOR]: "IPOR",
    [Strategies.STURDY]: "Sturdy",
    [Strategies.EIGEN_LAYER]: "Eignen Layer",
    [Strategies.USDC_TRICRYPTO]: "Convex USDC Tricrypto",
    [Strategies.MAKER_DSR]: "Maker DSR",
    [Strategies.ETH_PLUS]: "Convex ETH-ETH+",
    [Strategies.UNI_V3]: "Uniswap V3",
    [Strategies.FRAXLEND]: "Fraxlend",
    [Strategies.PENDLE]: "Pendle",
    [Strategies.USDC_PLUS]: "USDC+",
    [Strategies.HYUSD_EUSD]: "HYUSD-EUSD",
  }

  return (
    <div style={{ marginTop: "-46px" }}>
      <div
        style={{
          margin: "auto",
          backgroundColor: "white",
          padding: 25,
        }}
      >
        {report.borrowedCapital && (
          <Strategy
            name="Borrowed Capital"
            key="Borrowed Capital"
            strategy={report.borrowedCapital}
            showName={true}
          />
        )}
        <br />

        <h2>Lending & Borrows</h2>
        <LendingAndBorrows data={report.lendingAndBorrows} />

        <h2>Strategies</h2>
        {strategies.map((strategyName) => (report.strategies[strategyName] &&  <><Strategy
            name={strategyNames[strategyName] || strategyName}
            key={strategyName}
            strategy={report.strategies[strategyName]}
          /><br /></>))}

        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export function SummaryCard({ title, value }) {
  return (
    <Card style={{ height: "170px" }}>
      <Typography.Text type="secondary">{title}</Typography.Text>
      <Typography.Title
        level={2}
        style={{ margin: 0, marginBottom: "10px", fontWeight: 400 }}
      >
        {value}
      </Typography.Title>
      <DemoTinyArea />
      <Divider style={{ marginTop: 10, marginBottom: 5 }} />
    </Card>
  );
}
const DemoTinyArea = () => {
  const data = [
    264, 417, 438, 887, 309, 397, 550, 575, 563, 430, 525, 592, 492, 467, 513,
    546, 983, 340, 539, 243, 226, 192,
  ];
  const config = {
    autoFit: true,
    height: 22,
    data,
    smooth: true,
    areaStyle: {
      fill: "#d6e3fd",
    },
  };
  return <TinyArea {...config} />;
};

const LendingAndBorrows = ({ data }) => {
  return (
    <div>
      <Lending data={data.lending} summary={data.summary} name="Lending" />
      <br />
      <br />
      <Lending data={data.borrows} summary={data.summary} name="Borrows" />
      <br />
    </div>
  );
};

const Lending = ({ data, name, summary }) => {
  const currentValue = data
    .map(({ current: { value } }) => value)
    .reduce((a, b) => a + b, 0);
  const initialValue = data
    .map(({ initialBalance: { value } }) => value)
    .reduce((a, b) => a + b, 0);
  const pnl = data
    .map(({ pnl: { pnlInUsd } }) => pnlInUsd)
    .reduce((a, b) => a + b, 0);

  return (
    <table width="1000" style={{ textAlign: "center" }}>
      <thead
        style={{
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        <tr>
          <td colSpan={3} style={{ backgroundColor: "white" }}>
            <h3>{name}</h3>
          </td>
          <td colSpan={3} style={{ backgroundColor: "#cfe2f3" }}>
            Current
          </td>
          <td colSpan={3} style={{ backgroundColor: "#d9d2e9" }}>
            Initial Balance
          </td>
          <td colSpan={3} style={{ backgroundColor: "#d9ead3" }}>
            PnL
          </td>
        </tr>
      </thead>
      <thead style={{ backgroundColor: "#f4f4f4" }}>
        <tr>
          <td colSpan={2}>Asset</td>
          <td>Platform</td>
          <td style={{ backgroundColor: "#cfe2f3" }}>Amount</td>
          <td style={{ backgroundColor: "#cfe2f3" }}>Price</td>
          <td style={{ backgroundColor: "#cfe2f3" }}>Value</td>
          <td style={{ backgroundColor: "#d9d2e9" }}>Amount</td>
          <td style={{ backgroundColor: "#d9d2e9" }}>Price</td>
          <td style={{ backgroundColor: "#d9d2e9" }}>Value</td>
          <td style={{ backgroundColor: "#d9ead3" }}>By Token</td>
          <td style={{ backgroundColor: "#d9ead3" }}>By Base</td>
          <td style={{ backgroundColor: "#d9ead3" }}>USD</td>
        </tr>
      </thead>
      <tbody>
        {data.map((leg: any, index) => {
          return (
            <tr key={`${name}-${leg.legId}-${index}`}>
              <td
                style={{
                  textAlign: "left",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "stretch",
                }}
              >
                <img
                  width="15"
                  height="15"
                  style={{ marginLeft: "5px" }}
                  src={leg.thumb}
                />{" "}
                {leg.asset}
              </td>

              <td colSpan={2}>AAVE ({leg.chain})</td>
              <td>{toNiceNumber(leg.current.amount)}</td>
              <td>{toNiceNumber(leg.current.price)}</td>
              <td>${toNiceNumber(leg.current.value)}</td>
              <td>{toNiceNumber(leg.initialBalance.amount)}</td>
              <td>
                {toNiceNumber(leg.initialBalance.price)}
                {leg.initialBalance.priceInBase !== 0 &&
                  ` (${toNiceNumber(leg.initialBalance.priceInBase)})`}
              </td>
              <td>${toNiceNumber(leg.initialBalance.value)}</td>
              <td>{toNiceNumber(leg.pnl.pnlInToken)}</td>
              <td>{toNiceNumber(leg.pnl.pnlInBase)}</td>
              <td>${toNiceNumber(leg.pnl.pnlInUsd)}</td>
            </tr>
          );
        })}

        {name === "Borrows" && (
          <>
            <tr
              style={{
                borderTop: "1px solid #ccc",
                fontWeight: "bold",
                fontStyle: "italic",
              }}
            >
              <td colSpan={5}></td>
              <td>${toNiceNumber(currentValue)}</td>
              <td colSpan={2}></td>
              <td>${toNiceNumber(initialValue)}</td>
              <td />
              <td style={{ fontSize: "70%", textAlign: "right" }}>
                USD amount repaid
              </td>
              <td>${toNiceNumber(summary.totalRepay)}</td>
            </tr>
            <tr>
              <td colSpan={10}></td>
              <td
                style={{
                  fontSize: "70%",
                  fontWeight: "bold",
                  textAlign: "right",
                  borderBottom: "1px solid black",
                }}
              >
                Unrealized Pnl
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  fontStyle: "italic",
                  borderBottom: "1px solid black",
                }}
              >
                ${toNiceNumber(pnl + summary.totalRepay)}
              </td>
            </tr>
            <tr style={{ fontWeight: "bold", fontStyle: "italic" }}>
              <td colSpan={5}></td>
              <td />

              <td colSpan={2}></td>
              <td colSpan={2}></td>

              <td style={{ fontSize: "70%", textAlign: "right" }}>Total Pnl</td>
              <td>${toNiceNumber(pnl)}</td>
            </tr>
          </>
        )}

        {name === "Lending" && (
          <>
            <tr
              style={{
                borderTop: "1px solid #ccc",
                fontWeight: "bold",
                fontStyle: "italic",
              }}
            >
              <td colSpan={5}></td>
              <td>${toNiceNumber(currentValue)}</td>
              <td colSpan={2}></td>
              <td>${toNiceNumber(initialValue)}</td>
              <td colSpan={2} />

              <td>${toNiceNumber(summary.totalRepay)}</td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

const Strategy = ({
  strategy,
  name,
  showName,
}: {
  strategy: any;
  name: string;
  showName?: boolean;
}) => {
  // Ignore empty positions
  if (
    Number(strategy.summary.currentBalance) < 0.01 &&
    Number(strategy.summary.currentBalance) > -0.01
  ) {
    //return <></>;
  }

  return (
    <div>
      <table width="1000" style={{ textAlign: "center" }}>
        <thead
          style={{
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          <tr>
            <td colSpan={3} style={{ backgroundColor: "white" }}>
              <h3>{name}</h3>
            </td>
            <td colSpan={3} style={{ backgroundColor: "#cfe2f3" }}>
              Current
            </td>
            <td colSpan={3} style={{ backgroundColor: "#d9d2e9" }}>
              Initial Balance
            </td>
            <td colSpan={3} style={{ backgroundColor: "#d9ead3" }}>
              PnL
            </td>
          </tr>
        </thead>
        <thead style={{ backgroundColor: "#f4f4f4" }}>
          <tr>
            <td>Asset</td>
            <td>Leg Type</td>
            <td>{showName ? "Name" : "Base Asset"}</td>
            <td style={{ backgroundColor: "#cfe2f3" }}>Amount</td>
            <td style={{ backgroundColor: "#cfe2f3" }}>Price</td>
            <td style={{ backgroundColor: "#cfe2f3" }}>Value</td>
            <td style={{ backgroundColor: "#d9d2e9" }}>Amount</td>
            <td style={{ backgroundColor: "#d9d2e9" }}>Price</td>
            <td style={{ backgroundColor: "#d9d2e9" }}>Value</td>
            <td style={{ backgroundColor: "#d9ead3" }}>By Token</td>
            <td style={{ backgroundColor: "#d9ead3" }}>By Base</td>
            <td style={{ backgroundColor: "#d9ead3" }}>USD</td>
          </tr>
        </thead>
        <tbody>
          {Object.values(strategy.legs)
            .map((leg: any) => ({ ...leg, index: getLegIndex(leg) }))
            .sort((left, right) => left.index - right.index)
            .map((leg: any, index) => {
              return (
                <tr key={leg.legId + index}>
                  <td
                    style={{
                      textAlign: "left",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "stretch",
                    }}
                  >
                    {leg.thumb ? (
                      <img
                        width="15"
                        height="15"
                        style={{ marginLeft: "5px" }}
                        src={leg.thumb}
                      />
                    ) : (
                      <div style={{ width: "15px", height: "15px" }} />
                    )}{" "}
                    {leg.asset}
                  </td>
                  <td>{leg.legType}</td>
                  <td>{showName ? leg.name : leg.baseAsset}</td>
                  <td>{toNiceNumber(leg.current.amount)}</td>
                  <td>{toNiceNumber(leg.current.price)}</td>
                  <td>${toNiceNumber(leg.current.value)}</td>
                  <td>{toNiceNumber(leg.initialBalance.amount)}</td>
                  <td>
                    {toNiceNumber(leg.initialBalance.price)}
                    {leg.initialBalance.priceInBase !== 0 &&
                      ` (${toNiceNumber(leg.initialBalance.priceInBase)})`}
                  </td>
                  <td>${toNiceNumber(leg.initialBalance.value)}</td>
                  <td>{toNiceNumber(leg.pnl.pnlInToken)}</td>
                  <td>{toNiceNumber(leg.pnl.pnlInBase)}</td>
                  <td>${toNiceNumber(leg.pnl.pnlInUsd)}</td>
                </tr>
              );
            })}
          <tr
            style={{
              borderTop: "1px solid #ccc",
              fontWeight: "bold",
              fontStyle: "italic",
            }}
          >
            <td colSpan={5}></td>
            <td>${toNiceNumber(strategy.summary.currentBalance)}</td>
            <td colSpan={2}></td>
            <td>${toNiceNumber(strategy.summary.initialBalance)}</td>
            <td></td>
            <td style={{ fontSize: "70%", textAlign: "right" }}>
              Principle pnl
            </td>
            <td>${toNiceNumber(strategy.summary.principlePnl)}</td>
          </tr>
          <tr
            style={{
              fontWeight: "bold",
              fontStyle: "italic",
            }}
          >
            <td colSpan={10}></td>
            <td style={{ fontSize: "70%", textAlign: "right" }}>
              Pending rewards
            </td>
            <td>${toNiceNumber(strategy.summary.pendingRewards)}</td>
          </tr>
          <tr
            style={{
              fontWeight: "bold",
              fontStyle: "italic",
            }}
          >
            <td colSpan={10}></td>

            <td
              style={{
                fontSize: "70%",
                textAlign: "right",
                borderBottom: "1px solid black",
              }}
            >
              Collected rewards
            </td>
            <td style={{ borderBottom: "1px solid black" }}>
              ${toNiceNumber(strategy.summary.collectedRewards)}
            </td>
          </tr>
          <tr
            style={{
              fontWeight: "bold",
              fontStyle: "italic",
            }}
          >
            <td colSpan={10}></td>
            <td style={{ fontSize: "70%", textAlign: "right" }}>
              Unrealized Pnl
            </td>
            <td>
              $
              {toNiceNumber(
                strategy.summary.principlePnl + strategy.summary.pendingRewards
              )}
            </td>
          </tr>
          <tr
            style={{
              fontWeight: "bold",
              fontStyle: "italic",
            }}
          >
            <td colSpan={10}></td>
            <td style={{ fontSize: "70%", textAlign: "right" }}>Total Pnl</td>
            <td style={{}}>${toNiceNumber(strategy.summary.pnl)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
