import { useQueryClient, useMutation } from "react-query";
import axios from "axios";
import { message } from "antd";

export function useSetBalancerLive() {
  const client = useQueryClient();

  return useMutation(
    async (live: boolean) => {
      const api = process.env.REACT_APP_API_URI;
      await axios.post(api + "/balancer/live", { live });
      return live;
    },
    {
      onSuccess: (live) => {},
      onError: (ex: any) => {
        message.error(ex?.message);
      },
      onSettled: () => {
        client.invalidateQueries("loadBalancerLive");
      },
    }
  );
}
