import { useQuery } from "react-query";
import { useCallback } from "react";
import axios from "axios";
import { useCurrentPortfolioId } from "../../../hooks";

export function useListExecutionLocks() {
  const { portfolioId } = useCurrentPortfolioId();
  const queryItem = useCallback(async () => {
    const api = process.env.REACT_APP_API_URI;
    const { data } = await axios.get(
      api + `/workflow/locks`
    );
    return data.locks;
  }, [portfolioId]);

  return useQuery<any, Error>("listExecutionLocks", queryItem, {
    refetchOnWindowFocus: true,
    refetchInterval: 5000,
    initialData: [],
  });
}
