import { getApps } from "firebase/app";
import { useMemo } from "react";

export const useFirebase = () => {
  const apps = getApps();
  const app = useMemo(() => {
    if (apps.length > 0) {
      return apps[0];
    } else {
      return null;
    }
  }, [apps]);
  return app;
};
