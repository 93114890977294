import { useQuery } from "react-query";
import { useCallback } from "react";
import axios from "axios";
import { useCurrentPortfolioId } from "../../../hooks";

export function useLoadBorrowedCapital() {
  const { portfolioId } = useCurrentPortfolioId();
  const queryItem = useCallback(async () => {
    const api = process.env.REACT_APP_API_URI;
    const { data } = await axios.get(
      api + `/portfolio/${portfolioId}/strategy/borrowed_capital`
    );
    console.log(data);
    return data;
  }, [portfolioId]);

  return useQuery<any, Error>("loadBorrowedCapital", queryItem, {
    initialData: false,
  });
}
