import Numeral from "numeral";
import { isNil } from "lodash";
export const toNiceNumber = (num, decimals = 2) => {
  if (num === 0) {
    return 0;
  }
  decimals = decimals - 1;
  const format = `0.${"0".repeat(decimals)}aa`;

  return !isNil(num) ? Numeral(num).format(format) : "NA";
};
