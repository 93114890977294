import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Line, LineConfig } from "@ant-design/plots";
import { Card } from "antd";
import { useLoadPerformanceReport } from "./hooks/loadPerformanceReport";
import { toNiceNumber } from "@cosmopolis/common";
import { maxBy, minBy } from "lodash";

export const AumChart = () => {
  const { data: report, isLoading, isFetching } = useLoadPerformanceReport();
  const [data, setData] = useState<any>([]);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(10000000);

  useEffect(() => {
    if (report.length === 0) {
      return;
    }
    
    const parsedReport = report.map((item) => ({
      createdAt: `${item.datetime.substring(0, 10)}`,
      aum: Math.floor(item.aum),
    }));

    try{
        const maxAum: any= maxBy<any>(parsedReport, ({aum})=>aum as number).aum;
        const minAum: any = minBy<any>(parsedReport, ({aum})=>aum as number).aum;
        console.log(minAum)
        setMax(maxAum+maxAum*0.05);
       setMin(minAum-minAum*0.05);
    } catch(ex){
        console.log(ex);
    }
   

    setData(parsedReport);
  }, [report]);

  const config: LineConfig = {
    data,
    
    xField: "createdAt",
    yField: "aum",
    appendPadding: [15, 0, 0, 0],
    meta: {
      createdAt: {
        alias: "Date",
      },
      aum: {
        alias: "AUM",
        formatter: (v) => `$${toNiceNumber(v)}`,
      },
    },
    label: {
        
      style: {
        fontSize: 10,
        fill: "rgba(0,0,0,0.65)",
      },
      
    },
    
    
  };
  return (
    <Card loading={isLoading || isFetching} title="Daily AUM">
      <Line {...config} legend={false}  yAxis={{minLimit: min, maxLimit: max}} />
    </Card>
  );
};
