import {
  Layout,
  Row,
  Col,
  Dropdown,
  Space,
  Typography,
  Select,
  Form,
  Alert, 
} from "antd";
import {
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import { Sidebar } from "../Sidebar";
import React from "react";
import "./style.css";
const { Header, Content, Footer, Sider } = Layout;

export const Page = ({ children, navbar }: { children: any; navbar?: any }) => {
  const [collapsed, setCollapsed] = React.useState(false);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar collapsed={collapsed} onBreakpoint={setCollapsed} />
      <Layout className="site-layout">

        <Header
          className="page-header"
          style={{
            backgroundColor: "transparent",
            padding: 0,
            paddingLeft: 24,
            paddingRight: 24,
          }}
        >

          <Row gutter={12} align="middle">
            <Col style={{ paddingTop: "2px" }}>
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  style: {
                    fontSize: "18px",
                    lineHeight: "32px",
                    cursor: "pointer",
                    transition: "color 0.3s",
                  },
                  onClick: () => setCollapsed(!collapsed),
                }
              )}
            </Col>
            <Col lg={{ span: 4 }} xl={{ span: 2 }} md={{ span: 6 }}>
              <Form.Item style={{ margin: 0, width: "140px" }}>
                <Select
                  defaultValue="Fund Aristotle"
                  bordered={false}
                  style={{ padding: 0 }}
                >
                  <Select.Option value="Fund Aristotle">
                    <Typography.Text type="secondary">
                      Fund Aristotle
                    </Typography.Text>
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col flex="auto" />
            <Col span={2} style={{ textAlign: "right" }}></Col>
          </Row>
        </Header>

        <Content
          className="site-layout-background"
          style={{
            margin: 0,
            padding: 24,
            paddingTop: 0,
            minHeight: 280,
          }}
        >
          {children}
        </Content>

        <Footer style={{ textAlign: "center" }}></Footer>
      </Layout>
    </Layout>
  );
};
