import { Navigate, useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../hooks/currentUser";
import { Stack, Button } from "react-bootstrap";
import { useEffect } from "react";
export const RestrictedPage = () => {
  const { user } = useCurrentUser();
  const navigate = useNavigate();

  const handleClick = () => navigate("/login");

  useEffect(() => {
    if(user){
      navigate("/portfolio/status");
    }
  }, [user])


    return (
      <div
        style={{
          marginTop: "250px",
        }}
      >
        <Stack gap={2} className="col-md-5 mx-auto">
          <Button onClick={() => handleClick()} variant="outline-secondary">
            LOGIN
          </Button>
        </Stack>
      </div>
    );
};
