import { Page } from "../common";
import {
  useLoadCurrentPositions,
  useLoadPendingPositions,
  useSetBalancerLive,
  useLoadBalancerLive,
} from "./hooks";
import { PortfolioItem } from "./PortfolioItem";
import { PendingPositionItem } from "./PendingPositionItem";
import { LiveBalance } from "./LiveBalance";
import { Col, Row, Typography, Layout, Switch, Tabs } from "antd";
import { OrdersPane } from "./OrdersPane";
import { StrategiesPane } from "./StrategiesPane";
export function BalancerPage() {
  const { data: pendingPositions } = useLoadPendingPositions({
    portfolioId: "0x62ed28802362bb79ef4cee858d4f7aca5edd0490",
  });

  const { mutate: setBalancerLive, isLoading } = useSetBalancerLive();
  const { data: isLive, isFetching } = useLoadBalancerLive();
  return (
    <Page>
      <Layout.Header
        style={{
          backgroundColor: "transparent",
          padding: 0,
          marginTop: 0,
        }}
      >
        <Row>
          <Col flex={1}>
            <Typography.Title level={1}>Balancer</Typography.Title>
          </Col>
          <Col>
            <Switch
              loading={isLoading || isFetching}
              checkedChildren="Live"
              unCheckedChildren="Stopped"
              checked={isLive}
              onClick={(val) => setBalancerLive(val)}
            />
          </Col>
        </Row>
      </Layout.Header>
      <Tabs
        defaultActiveKey="1"
        tabBarStyle={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
      >
        <Tabs.TabPane tab="Live Positions" key="1">
          <Row gutter={16} style={{ marginBottom: 32 }}>
            <Col className="gutter-row" span={24}>
              <LiveBalance />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" md={{ span: 24 }} lg={{ span: 12 }}>
              <Typography.Title level={3}>Pending Positions</Typography.Title>
              {pendingPositions?.map((item, index) => (
                <PendingPositionItem key={"pending" + index} item={item} />
              ))}
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Strategies" key="2">
          <StrategiesPane />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Trades" key="3">
          <OrdersPane live={isLive} />
        </Tabs.TabPane>
      </Tabs>
    </Page>
  );
}
