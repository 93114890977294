import { CurrentUser } from "../../context";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "react-query";
import "react-toastify/dist/ReactToastify.css";
import "./root.css";

/**
 * App context setup
 */
export const Root = ({ children }: { children: any }) => {
  const queryClient = new QueryClient();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <CurrentUser>{children}</CurrentUser>
        <ToastContainer />
      </QueryClientProvider>
    </>
  );
};
