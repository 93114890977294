import { useCallback, useState } from "react";
import { useDatabase } from "../../../hooks";
import { useQuery } from "react-query";
import {
  getDocs,
  doc,
  collection,
  query,
  orderBy,
  limit,
  getDoc,
  where,
} from "firebase/firestore";

export const useLoadReport = ({
  id,
  skip,
  timestamp,
}: { id?: string; skip?: number; timestamp?: any } = {}) => {
  const database = useDatabase();
  const queryItem = useCallback(async () => {
    if (database) {
      const ref = collection(database, "portfolio-reports");
      let data;
      if (!id && !timestamp) {
        const reportQuery = query(
          ref,
          orderBy("timestamp", "desc"),
          limit(skip || 1)
        );
        const docs = await getDocs(reportQuery);
        const first = docs.docs[docs.docs.length - 1];
        data = first.data();
      } else if (timestamp) {
        const parsed = new Date(timestamp).getTime();
        const reportQuery = query(
          ref,
          orderBy("timestamp", "asc"),
          where("timestamp", ">=", parsed),
          limit(skip || 1)
        );
        let report = await getDocs(reportQuery);
        if (report.size === 0) {
          const reportQuery = query(
            ref,
            orderBy("timestamp", "desc"),
            where("timestamp", "<=", parsed),
            limit(skip || 1)
          );
          report = await getDocs(reportQuery);
        }
        data =
          report.size > 0 ? report.docs[report.docs.length - 1].data() : null;
      }

      return { report: data };
    } else {
      throw new Error("Functions not initialized");
    }
  }, [database, skip, timestamp]);

  return useQuery<{ report: any }, Error>(
    ["loadReport", timestamp, skip],
    queryItem,
    {
      initialData: { report: null },
    }
  );
};
