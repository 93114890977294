import { useQuery } from "react-query";
import { useCallback } from "react";
import axios from "axios";


export function useGetFtxBalances() {
  const queryItem = useCallback(async () => {
    const api = process.env.REACT_APP_API_URI;
    const { data } = await axios.get(api + "/cex/ftx/treasury");
    return data || {};
  }, []);

  return useQuery<
    { [asset: string]: { size: number; usdValue: number } },
    Error
  >("getFtxBalances", queryItem, {
    initialData: {},
  });
}

export default useGetFtxBalances;
