import axios from "axios";
import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";

export function useExecuteWorkflow() {
  const client = useQueryClient();

  const { mutate, isLoading } = useMutation(
    async (values: any) => {
      const api = process.env.REACT_APP_API_URI;
      const { workflow, params } = values;
      return axios.post(api + `/workflow/execute`, {
        workflow,params
      });
    },
    {
      onSuccess: (data) => {
        message.success("Workflow started");
      },
      onError: (ex: any) => {
        message.error(ex?.message);
      },
      onSettled: () => {
        client.invalidateQueries("listExecutionLocks");
      },
    }
  );

  return { mutate, isLoading };
}
