import "./style.css";

import { Breadcrumb, Divider, Layout, Menu } from "antd";
import {
  HeatMapOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MoneyCollectOutlined,
  PieChartOutlined,
  RadarChartOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import type { MenuProps } from "antd";

const { Sider } = Layout;
const { SubMenu } = Menu;
type MenuItem = Required<MenuProps>["items"][number];

export const Sidebar = ({ collapsed, onBreakpoint }) => {
  let location = useLocation();
  let history = useNavigate();

  return (
    <Sider
      collapsed={collapsed}
      width={"260px"}
      trigger={null}
      collapsedWidth={0}
      collapsible
      breakpoint="md"
      onBreakpoint={onBreakpoint}
    >
      <div
        className="logo-container"
        style={{
          borderBottom: "1px solid rgba(255, 255, 255, 0.15)",
          height: 100,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          paddingLeft: 24,
        }}
      >
        <div className="logo">
          {!collapsed && <img src="/logo-white.webp" />}
        </div>
      </div>
      <Menu
        defaultOpenKeys={["/portfolio"]}
        style={{ marginTop: "20px" }}
        theme="dark"
        activeKey={location.pathname}
        mode="inline"
        items={[
          {
            key: "/portfolio",
            icon: <PieChartOutlined />,
            label: "Portfolio & Reports",
            children: [
              {
                key: "/portfolio/status",
                label: "Status Report",
                onClick: () => history("/portfolio/status"),
              },
              {
                key: "/portfolio/performance",
                label: "Performance Report",
                onClick: () => history("/portfolio/performance"),
              },
              {
                key: "/portfolio/periodic",
                label: "Periodic Report",
                onClick: () => history("/portfolio/periodic"),
              },
              {
                key: "/portfolio/transactions",
                label: "Transactions",
                onClick: () => history("/portfolio/transactions"),
              },
            ],
          },
          {
            key: "/balancer",
            icon: <RadarChartOutlined />,
            onClick: () => history("/balancer"),
            label: "Balancer",
          },
          {
            key: "/borrow",
            icon: <MoneyCollectOutlined />,
            onClick: () => history("/borrow"),
            label: "Borrowed Capital",
          },
          {
            key: "/workflow",
            icon: <HeatMapOutlined />,
            onClick: () => history("/workflow"),
            label: "Workflow",
          },
        ]}
      ></Menu>
    </Sider>
  );
};
