import { Card, Table, Alert, Button, Spin, Row, Col, Space, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { formatDistance } from "date-fns";
import { Order } from "../../common";

import { toNiceNumber, toShortISO } from "@cosmopolis/common";

export function BorrowHistory({ transactions }: { transactions?: any[] }) {
  const columns: ColumnsType<Order> = [
    {
      title: "Time",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (val) =>
        `${toShortISO(new Date(val))} (${formatDistance(
          new Date(val),
          new Date()
        )} ago)`,
      sorter: (a, b) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
      defaultSortOrder: "descend",
    },
    {
      title: "Leg",
      dataIndex: "legId",
      key: "legId",
    },
    {
      title: "Amount",
      key: "amount",
      render: (val) => `${toNiceNumber(val.amount, 3)}`,
    },
    {
      title: "Price",
      key: "price",
      dataIndex: "price",
    },
  ];

  return (
    <Card>
      <Table
        pagination={{ pageSize: 100 }}
        size={"small"}
        columns={columns}
        dataSource={transactions}
      />
    </Card>
  );
}
