import { useQueryClient, useMutation } from "react-query";
import axios from "axios";
import { message } from "antd";

export function useParsePendingPosition() {
  const client = useQueryClient();

  return useMutation(
    async ({
      timestamp,
      portfolioId,
      asset,
    }: {
      timestamp: number;
      portfolioId: string;
      asset: string;
    }) => {
      const api = process.env.REACT_APP_API_URI;
      await axios.post(api + "/balancer/parse", {
        timestamp,
        portfolioId,
        asset,
      });
    },
    {
      onSuccess: (live) => {},
      onError: (ex: any) => {
        message.error(ex?.message);
      },
      onSettled: () => {
        client.invalidateQueries("loadPendingPositions");
        client.invalidateQueries("loadRequiredBalance");
      },
    }
  );
}
