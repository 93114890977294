import {
  useGetFtxBalances,
  useLoadRequiredBalance,
  useRegisterPendingPosition,
} from "./hooks";
import {
  Card,
  Avatar,
  Button,
  List,
  Skeleton,
  Space,
  Statistic,
  Row,
  Col,
  Typography,
  Divider,
  Modal,
  Drawer,
  Form,
  Input,
  Select,
  InputNumber,
} from "antd";
import { toNiceNumber, Strategies, LegTypes } from "@cosmopolis/common";
import { useReducer, useState } from "react";
import { AddPendingPositionModal } from "./AddPendingPositionModal";

const { Option } = Select;
export function LiveBalance() {
  const ftxData = useGetFtxBalances();
  const { data: required, isLoading: balanceFetching } =
    useLoadRequiredBalance();
  const [showModal, setShowModal] = useState(false);
  const [formInitialValues, setFormInitialValues] = useReducer(
    (state, action) => {
      return state;
    },
    {
      asset: "",
      amount: 0,
    }
  );
  const { data: ftx, isLoading: ftxFetching } = ftxData;
  return (
    <>
      <Card
        loading={ftxFetching || balanceFetching}
        style={{
          borderRadius: 5,
          boxShadow:
            "0 1px 1px 0 rgb(60 64 67 / 30%), 0 1px 2px 1px rgb(60 64 67 / 15%)",
        }}
      >
        <Row key={"live"} justify="space-between" align="middle">
          <Col span={6} style={{ textAlign: "center" }}>
            <Typography.Text type="secondary">Asset</Typography.Text>
          </Col>
          <Col span={4} style={{ textAlign: "center" }}>
            <Typography.Text type="secondary">Required</Typography.Text>
          </Col>
          <Col span={4} style={{ textAlign: "center" }}>
            <Typography.Text type="secondary">Current</Typography.Text>
          </Col>
          <Col span={4} style={{ textAlign: "center" }}>
            <Typography.Text type="secondary">Diff</Typography.Text>
          </Col>
          <Col span={4} style={{ textAlign: "center" }}>
            <Typography.Text type="secondary">Actions</Typography.Text>
          </Col>
        </Row>
        <Divider />

        {Object.keys(required!).map((key, index) => {
          const title = key;
          const requiredBalance = required![key];
          const currentBalance = ftx![key]?.size;
          const diff = toNiceNumber(currentBalance! - requiredBalance, 4);
          return (
            <Row
              key={"live" + index}
              justify="space-between"
              align="middle"
              gutter={[24, 24]}
              style={{ height: 56 }}
            >
              <Col span={6} style={{ textAlign: "left" }}>
                <Space align="center">
                  <div
                    style={{
                      borderRadius: 100,
                      width: 10,
                      height: 10,
                      backgroundColor: "green",
                      marginRight: 25,
                    }}
                  ></div>
                  <Statistic value={title} />
                </Space>
              </Col>
              <Col span={4} style={{ textAlign: "center" }}>
                <Statistic value={toNiceNumber(requiredBalance, 3)} />
              </Col>
              <Col span={4} style={{ textAlign: "center" }}>
                <Statistic value={toNiceNumber(currentBalance, 3)} />
              </Col>{" "}
              <Col span={4} style={{ textAlign: "center" }}>
                <Statistic value={diff} />
              </Col>
              <Col span={4} style={{ textAlign: "center" }}>
                <Space>
                  <Typography.Link
                    onClick={() => {
                      setFormInitialValues({
                        asset: title,
                        amount: requiredBalance,
                      });
                      setShowModal(true);
                    }}
                  >
                    Adjust
                  </Typography.Link>
                </Space>
              </Col>
            </Row>
          );
        })}
      </Card>
      <AddPendingPositionModal
        {...formInitialValues}
        show={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
}
