import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Column, ColumnConfig } from "@ant-design/plots";
import { Card } from "antd";
import { useLoadPerformanceReport } from "./hooks/loadPerformanceReport";
import { toNiceNumber } from "@cosmopolis/common";
import { maxBy, minBy } from "lodash";

export const RevenueChart = () => {
  const { data: report, isLoading, isFetching } = useLoadPerformanceReport();
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (report.length === 0) {
      return;
    }
    
    const parsedReport = report.map((item) => {
      let offset = 24/item.periodHours;
      console.log(item.datetime, offset, item)
     return {createdAt: `${item.datetime.substring(0, 10)}`,
      revenue: Math.floor(item.periodRevenue*offset),
      interest: Math.floor(item.periodInterest*offset),
      netRevenue: Math.floor(item.periodRevenue*offset-item.periodInterest*offset)}
  }).slice(-60);

    parsedReport.forEach(item=>{
      if(item.revenue<-3000 || item.revenue>20000){
        item.revenue = 0;
      }
      if(item.interest < -3000 || item.interest >3000){
        item.interest = 0;
      }
      if(item.netRevenue < -3000 || item.netRevenue > 10000){
        item.netRevenue = 0;
      }
    })
    const revenue = parsedReport.map((item)=>({ series: "gross revenue", datetime: item.createdAt, value: item.revenue }));
    const interest = parsedReport.map((item)=>({ series: "interest", datetime: item.createdAt, value: Math.abs(item.interest) }));
    const net = parsedReport.map((item)=>({ series: "net revenue", datetime: item.createdAt, value: item.netRevenue }));
    const merged = revenue.concat(interest).concat(net);
    console.log(merged)
    setData(merged);
  }, [report]);

  const config: ColumnConfig= {
    data,
    isGroup: true,
    yField: 'value',
    xField: 'datetime',

    /** 自定义颜色 */
    // color: ['#1383ab', '#c52125'],
    seriesField: 'series',
    marginRatio: 0,
    
  };
  return (
    <Card loading={isLoading || isFetching} title="Daily Revenue Last 60 Day">
   <Column {...config} />
    </Card>
  );
};
