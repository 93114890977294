import { useListExecutionLocks, useResetWorkflow, useExecuteWorkflow } from "./hooks";
import { Avatar, Button, List, Skeleton, Modal, Typography, Form, Input, Select } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useState, useEffect } from "react";
import { Strategies, Workflows } from "../../common";

export  function WorkflowList(){
    const { data, isFetching, isError, error } = useListExecutionLocks();
    const [showWorkflowConfig, setShowWorkflowConfig] = useState(false);
    const [activeWorkflow, setActiveWorkflow] = useState<Workflows|null>(null);
    const [workflows, setWorkflows] = useState([
        { workflowId: Workflows.EthPlusWithdraw, name: "ETH+ Wirhdraw", running: false, description: "Withdraw funds from ETH+ pool in Convex and Curve" },
        { workflowId: Workflows.ConvexClaimRewards, name: "Convex Claim Rewards", running: false, description: "Collect rewards from a Convex pool" },
    ]);

    useEffect(()=>{
        if(data){
            data.forEach(item=>{
                const workflow = workflows.find(w=>{
                    return Workflows[w.workflowId] == item.workflow});
                if(workflow){
                    workflow.running = item.lock;
                }
            })
            setWorkflows([...workflows]);
        }
    },[data])

    return <><List
        style={{maxWidth: "800px"}}
        itemLayout="horizontal"
        dataSource={workflows}
        renderItem={(item) => {
            const {mutate:resetWorkflow, isLoading: resetIsLoading} = useResetWorkflow();
        return  <List.Item
        actions={item.running ?[ <a key="reset-workflow" onClick={()=>resetWorkflow({ workflow: item.workflowId})}>reset</a>] : [<a onClick={()=>{setActiveWorkflow(item.workflowId); setShowWorkflowConfig(true)}} key="execute-workflow">execute</a>]}
        >
            <Skeleton avatar title={false} loading={false} active>
                <List.Item.Meta
                            avatar={<div style={{width: 10, height: 10, borderRadius: 10, backgroundColor: item.running ? "green" : "#dddddd"}}></div>}
                    title={item.name}
                    description={item.description}
                />

            </Skeleton>
    </List.Item>
    }}
     
  />   
   <WorkflowParamsModal activeWorkflow={activeWorkflow} open={showWorkflowConfig} setVisible={setShowWorkflowConfig} />
  </> 
}

const WorkflowParamsModal = ({ activeWorkflow, open, setVisible}) => {
    const {mutate:executeWorkflow, isLoading: executeIsLoading} = useExecuteWorkflow();

    const [form] = Form.useForm();

    let body = <div />
    if(activeWorkflow==Workflows.EthPlusWithdraw){
        body = <EthPlusWithdrawForm form={form} />
    } else if(activeWorkflow==Workflows.ConvexClaimRewards){
        body = <ConvexClaimRewardsForm form={form} />
    }

    return <Modal title="Configuration" open={open} confirmLoading={executeIsLoading}  onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            executeWorkflow({workflow: activeWorkflow!, params: values})

            setVisible(false);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }} onCancel={()=>setVisible(false)}>
        <Typography.Title level={4}><WorkflowTitle workflowId={activeWorkflow!} /></Typography.Title>
            {body }
      </Modal>
}


const WorkflowTitle = ({workflowId}:{workflowId: Workflows})=>{ 
    let title = workflowId.toString();
    switch(workflowId){
        case Workflows.EthPlusWithdraw:
            title = "ETH+ Withdraw"
    }
    return <>{title}</>
}

const EthPlusWithdrawForm = ({ form })=>{ 
    const{ Option } = Select;
return <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ vault: '33' }}
      >
         <Form.Item name="vault" label="Vault"
          rules={[{ required: true }]}
          >
         <Select placeholder="select Fireblocks vault" >
          <Option value="4">Aristo</Option>
          <Option value="33">Blake</Option>
        </Select>
        </Form.Item>
        <Form.Item
          name="amount"
          label="LP Amount"
          
          rules={[{ required: true, message: 'Please input amount to withdraw!' }]}
        >
          <Input placeholder="Amount of Convex LP tokens to withdraw"/>
        </Form.Item>
       
      
      </Form>
}


const ConvexClaimRewardsForm = ({ form })=>{ 
    const{ Option } = Select;
return <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
      >
         <Form.Item name="strategyId" label="Convex Strategy"
          rules={[{ required: true }]}
          >
         <Select placeholder="select strategy" >
          <Option value={Strategies.EUSD_FRAX_LP}>EUSD-FRAX</Option>
          <Option value={Strategies.USDC_TRICRYPTO}>USDC TriCrypto</Option>
        </Select>
        </Form.Item>
       
      
      </Form>
}