import { useQuery } from "react-query";
import { useCallback } from "react";
import axios from "axios";

export function useLoadPerformanceReport() {
  const queryItem = useCallback(async () => {
    const api = process.env.REACT_APP_API_URI;
    const { data } = await axios.get(api + "/report/revenue/history");
    console.log(data);
    return data || [];
  }, []);

  return useQuery<any, Error>("loadPerformanceReport", queryItem, {
    refetchOnWindowFocus: false,
    initialData: [],
  });
}
